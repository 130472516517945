
import { Html5Entities } from "html-entities";
import Vue from "vue";
import { mapGetters } from "vuex";

import { hasSentenceImbalancedTags, htmlToPlainText } from "@/utils/helpers";
import { MutationTypes } from "@/store";
import { Comment, Sentence } from "@/store/types";
import { escapeRegExp } from "lodash";

export default Vue.extend({
    props: {
        sentenceId: Number,
        source: Boolean,
    },
    computed: {
        ...mapGetters(["comments", "display", "sentences"]),
        comment(): Comment {
            return this.comments[this.sentenceId];
        },
        hasComment(): boolean {
            return !!this.comment.text;
        },
        hasImbalancedTags(): boolean {
            return hasSentenceImbalancedTags(this.sentence);
        },
        isBeingDeepLTranslated(): boolean {
            return this.sentence.display.isBeingDeepLTranslated;
        },
        isBeingEdited(): boolean {
            return this.sentence.display.isBeingEdited;
        },
        // a merged sentence would have an empty original value, we want to skip those
        isEmpty(): boolean {
            return this.sentence.original === "" || typeof this.sentence.original === "undefined";
        },
        isHovered(): boolean {
            return this.sentence.display.isBeingHovered;
        },
        sentence(): Sentence {
            return this.sentences[this.sentenceId];
        },
        showAsDeepLTranslated(): boolean {
            return (
                !this.source &&
                !this.showAsManuallyTranslated &&
                !!this.sentence.deepLTranslation &&
                !this.hasImbalancedTags
            );
        },
        showAsImbalanced(): boolean {
            return this.hasImbalancedTags;
        },
        showAsManuallyTranslated(): boolean {
            return !this.source && !!this.sentence.hasBeenTranslated && !this.hasImbalancedTags;
        },
        text(): string {
            let text = this.source ? this.sentence?.original : this.sentence?.translated;

            text = htmlToPlainText(text);

            const searchString = this.source ? this.display.search.sourceTerm : this.display.search.targetTerm;
            if (searchString === "") {
                return text;
            }

            // we need to HTML decode, perform the matching, then re-encode
            return Html5Entities.decode(text).replaceAll(
                new RegExp(`(.*?)(${escapeRegExp(searchString)})`, "ig"),
                (_, before, inside) => {
                    return (
                        Html5Entities.encode(before) + `<span class="bg-warning">${Html5Entities.encode(inside)}</span>`
                    );
                }
            );
        },
    },
    methods: {
        handleMouseLeave() {
            this.$store.commit(MutationTypes.hoverSentence, { sentenceId: this.sentenceId, isHovered: false });
        },
        handleMouseOver() {
            this.$store.commit(MutationTypes.hoverSentence, { sentenceId: this.sentenceId, isHovered: true });
        },
    },
});
