
import Vue from "vue";
import { BvModalEvent } from "bootstrap-vue";

export default Vue.extend({
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
    },
    methods: {
        handleHide(event: BvModalEvent) {
            // if the event has a trigger (clicking on a close button, clicking on the backdrop, etc), do not close the
            // modal. We want to close it when the isVisible value changes, which will show as an empty trigger
            if (event.trigger) {
                event.preventDefault();
            }
        }
    }
});
