var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isDocumentLoaded)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-1",class:{ disabled: !_vm.isTranslating }},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('TimeElapsed',{staticClass:"mr-1",attrs:{"percent-done":_vm.getPercentDone('translation'),"mode":"translation"}}),_c('b-progress',{staticClass:"progression",attrs:{"max":100}},[_c('b-progress-bar',{attrs:{"value":_vm.getPercentDone('translation'),"variant":"success"}}),_c('b-progress-bar',{attrs:{"value":_vm.getPercentDone('deepL'),"variant":"warning"}})],1),_c('small',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right.title",value:(
                        `${_vm.displayNumber(_vm.getWordsCount('translation'))} / ${_vm.displayNumber(_vm.wordsCount)} words`
                    ),expression:"\n                        `${displayNumber(getWordsCount('translation'))} / ${displayNumber(wordsCount)} words`\n                    ",modifiers:{"right":true,"title":true}}],staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.getRoundPercentageDone("translation"))+"% ")])],1),_c('b-badge',{staticClass:"ml-2 clickable",attrs:{"variant":"success"},on:{"click":function($event){return _vm.toggleTimerMode('translation')}}},[_vm._v(" Translating ")])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between",class:{ disabled: !_vm.isProofreading }},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('TimeElapsed',{staticClass:"mr-1",attrs:{"percent-done":_vm.getPercentDone('proofread'),"mode":"proofread"}}),_c('b-progress',{staticClass:"progression",attrs:{"max":100}},[_c('b-progress-bar',{attrs:{"value":_vm.getPercentDone('proofread'),"variant":"primary"}})],1),_c('small',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right.title",value:(
                        `${_vm.displayNumber(_vm.getWordsCount('proofread'))} / ${_vm.displayNumber(_vm.wordsCount)} words`
                    ),expression:"\n                        `${displayNumber(getWordsCount('proofread'))} / ${displayNumber(wordsCount)} words`\n                    ",modifiers:{"right":true,"title":true}}],staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.getRoundPercentageDone("proofread"))+"% ")])],1),_c('b-badge',{staticClass:"ml-2 clickable",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.toggleTimerMode('proofread')}}},[_vm._v(" Proofreading ")])],1)]),_c('div',{staticClass:"clickable ml-2 d-flex align-items-center",class:{
            'text-success': _vm.isTranslating,
            'text-primary': _vm.isProofreading,
        },on:{"click":function($event){return _vm.toggleTimerMode(null)}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:('Toggle mode'),expression:"'Toggle mode'",modifiers:{"bottom":true}}],attrs:{"icon":"stopwatch","size":"2x"}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }