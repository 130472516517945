
import { find, findIndex, some } from "lodash/fp";
import Vue from "vue";
import { mapGetters } from "vuex";

import Navigation from "@/components/Navigation/index.vue";
import LinkPopover from "@/components/LinkPopover.vue";
import SentenceEditor from "@/components/SentenceEditor.vue";
import SentenceDisplay from "@/components/SentenceDisplay.vue";
import { ActionTypes, MutationTypes } from "@/store";
import { Paragraph, State } from "@/store/types";
import { forceNextTick, notifyError } from "@/utils/helpers";
import { max, min } from "lodash";

type SentenceLocation = {
    id: number;
    scrollTop: number;
};

const emptyPopover = {
    left: 0,
    maxX: 0,
    text: "",
    top: 0,
};

// number of paragraph to display divided by two
const ACTIVE_PARAGRAPHS_HALF_COUNT = 20;

// sync the scrolling not on the top of the view, but at this height. For ex: 100 means the sentences at
// 100px from the top of view will be synced
const SYNC_HEIGHT = 100;

export default Vue.extend({
    components: {
        LinkPopover,
        Navigation,
        SentenceDisplay,
        SentenceEditor,
    },
    props: {
        source: Boolean,
    },
    data() {
        return {
            activeParagraphs: [] as Paragraph[],
            disableScrollSync: false,
            editedSentenceId: null as null | number,
            highlightedCommentMinId: 0,
            isPopoverOpen: false,
            isPopoverTranslateOpen: false,
            popover: emptyPopover,
            search: "",
            sentenceLocations: [] as SentenceLocation[],
        };
    },
    created() {
        window.addEventListener("resize", this.handleWindowResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleWindowResize);
    },
    watch: {
        "display.scrollTo"(value: State["display"]["scrollTo"]) {
            const { id, progressionRatio } = value;

            this.scrollToSentence(id, progressionRatio);
        },
        "display.search.sourceTerm"(value) {
            if (this.source) {
                this.search = value;
            }
        },
        "display.search.targetTerm"(value) {
            if (!this.source) {
                this.search = value;
            }
        },
        editedSentence(value) {
            const id = value?.id;

            if (!id) {
                return;
            }

            if (!this.visibleSentenceIds.includes(id)) {
                this.scrollToSentence(id);
            }
        },
        isFirstParagraphActive() {
            this.updateSentenceLocations();
        },
        isLastParagraphActive() {
            this.updateSentenceLocations();
        },
        paragraphs() {
            this.activeParagraphs = this.paragraphs.slice(0, ACTIVE_PARAGRAPHS_HALF_COUNT * 2);

            this.updateSentenceLocations();
        },
    },
    computed: {
        ...mapGetters([
            "comments",
            "config",
            "display",
            "paragraphs",
            "sections",
            "sentenceIds",
            "sentences",
            "editedSentence",
        ]),
        hasComments(): boolean {
            return some(c => !!c.text, this.comments);
        },
        isEdited(): boolean[] {
            return this.sentenceIds.map((id: number) => id === this.editedSentenceId);
        },
        isFirstParagraphActive(): boolean {
            return this.minActiveParagraphIndex === 0;
        },
        isLastParagraphActive(): boolean {
            if (this.paragraphs.length === 0) {
                return true;
            }

            const paragraphMaxIndex = this.paragraphs[this.paragraphs.length - 1].index;

            return this.maxActiveParagraphIndex === paragraphMaxIndex;
        },
        maxActiveParagraphIndex(): number {
            if (!this.activeParagraphs.length) {
                return 0;
            }

            return this.activeParagraphs[this.activeParagraphs.length - 1].index;
        },
        minActiveParagraphIndex(): number {
            if (!this.activeParagraphs.length) {
                return 0;
            }

            return this.activeParagraphs[0].index;
        },
        panel(): HTMLElement {
            return this.$refs.panel as HTMLElement;
        },
        visibleSentenceIds(): number[] {
            const { panel } = this;

            const minScroll = panel.scrollTop;
            const maxScroll = panel.clientHeight + panel.scrollTop;

            return this.sentenceLocations
                .filter(sl => sl.scrollTop >= minScroll && sl.scrollTop < maxScroll)
                .map(sl => sl.id);
        },
    },
    methods: {
        closePopover() {
            this.popover = emptyPopover;
            this.isPopoverOpen = false;
        },
        getNextSentenceScrollTop(id: number): number {
            const { sentenceLocations } = this;
            const index = findIndex(["id", id], sentenceLocations);

            const isLastItem = index === sentenceLocations.length - 1;
            return isLastItem ? this.panel.scrollHeight : sentenceLocations[index + 1].scrollTop;
        },
        getSentenceElement(id: number): HTMLElement {
            const ref = `sentence_${id}`;

            // this can happen if the sentences are loading but not yet displayed in the UI
            if (!this.$refs[ref]) {
                throw new Error("No sentence could be found");
            }

            return (this.$refs[ref] as Vue[])[0].$el as HTMLElement;
        },
        handleMergePrevious() {
            if (!this.editedSentence) {
                return;
            }

            this.$store.dispatch(ActionTypes.mergePrevious, this.editedSentence.id);
        },
        // this is the event where we detect some text has been selected
        async handleMouseUp() {
            // wait for the DOM to be synced before getting the selection, or it
            // can be outdated
            await this.$nextTick();

            const selection = window.getSelection();

            if (!selection || !selection.rangeCount) {
                return;
            }

            const range = selection.getRangeAt(0);
            const { panel } = this;

            // @todo - is this useful ?
            // check if the selected text is in the source or translated panel
            const isInPanel = panel.contains(range.commonAncestorContainer);
            if (!isInPanel) {
                return this.closePopover();
            }

            // get rectangle positioning
            const rectangle = range.getBoundingClientRect();
            const parentRectangle = panel.getBoundingClientRect();

            const selectedText = selection.toString();

            // update popover
            this.isPopoverOpen = !!selectedText;
            this.popover = {
                left: rectangle.left - parentRectangle.left,
                maxX: parentRectangle.width,
                text: selectedText,
                // take scrolling into account
                top: rectangle.top - parentRectangle.top + panel.scrollTop,
            };
        },
        handleNextSentence() {
            this.$store.dispatch(ActionTypes.editNextSentence);
        },
        handlePreviousSentence() {
            this.$store.dispatch(ActionTypes.editPreviousSentence);
        },
        handleSearchPrevious() {
            this.$store.commit(MutationTypes.search, {
                isSource: this.source,
                searchString: this.search,
            });

            const matchIds: number[] = this.source
                ? this.display.search.sourceMatchIds
                : this.display.search.targetMatchIds;

            if (matchIds.length === 0) {
                return;
            }

            const previousId =
                max(matchIds.filter(id => id < this.display.scrollTo.id)) || matchIds[matchIds.length - 1];

            this.scrollToSentence(previousId);
        },
        handleSearchNext() {
            this.$store.commit(MutationTypes.search, {
                isSource: this.source,
                searchString: this.search,
            });

            const matchIds: number[] = this.source
                ? this.display.search.sourceMatchIds
                : this.display.search.targetMatchIds;

            if (matchIds.length === 0) {
                return;
            }

            const nextId = min(matchIds.filter(id => id > this.display.scrollTo.id)) || matchIds[0];

            this.scrollToSentence(nextId);
        },
        async handleScroll() {
            await this.loadAdjacentParagraph();

            if (this.disableScrollSync) {
                return;
            }

            const { panel } = this;

            const scrollTop = panel.scrollTop + SYNC_HEIGHT;

            const getActiveSentenceLocation = (scrollTop: number): SentenceLocation => {
                const { sentenceLocations } = this;

                const sentenceLocation = sentenceLocations.find((sst: SentenceLocation) => {
                    return sst.scrollTop <= scrollTop && scrollTop < this.getNextSentenceScrollTop(sst.id);
                });

                if (!sentenceLocation) {
                    throw new Error("No sentence location found");
                }

                return sentenceLocation;
            };

            // get sentence location data
            const activeSentenceLocation = getActiveSentenceLocation(scrollTop);

            const sentenceHeight =
                this.getNextSentenceScrollTop(activeSentenceLocation.id) - activeSentenceLocation.scrollTop;
            const progressionRatio = (scrollTop - activeSentenceLocation.scrollTop) / sentenceHeight;

            this.$store.commit(MutationTypes.scrollToLocation, {
                id: activeSentenceLocation.id,
                progressionRatio,
            });
        },
        handleWindowResize() {
            this.updateSentenceLocations();
        },
        async loadAdjacentParagraph() {
            const { panel } = this;

            // because when scrolling we are syncing the position through progressionRatio, it might not reach the exact
            // border of the translation area and it might be a few pixels off. If we are within 'pixelOffset' pixels of
            // the edge, trigger the loading of adjacent paragraph
            const pixelOffset = 10;

            // if we're at the bottom of the screen
            if (panel.clientHeight + panel.scrollTop > panel.scrollHeight - 10) {
                // if we're at the end, don't do anything
                const isLastParagraphReached =
                    this.paragraphs[this.paragraphs.length - 1].index === this.maxActiveParagraphIndex;
                if (isLastParagraphReached) {
                    return;
                }

                // disable scroll sync while we move the scrolling around
                this.disableScrollSync = true;
                // close popover - it would be offset
                this.closePopover();

                // we're going to remove some paragraphs - get their height so we can offset the scroll by the same
                // value and keep the user looking at the same sentence
                const removedHeight = this.getSentenceElement(
                    this.activeParagraphs[ACTIVE_PARAGRAPHS_HALF_COUNT].sentenceIds[0]
                ).offsetTop;

                // remove previous paragraphs, load new ones
                this.activeParagraphs = this.activeParagraphs
                    .slice(ACTIVE_PARAGRAPHS_HALF_COUNT)
                    .concat(
                        this.paragraphs.slice(
                            this.maxActiveParagraphIndex + 1,
                            Math.min(
                                this.maxActiveParagraphIndex + ACTIVE_PARAGRAPHS_HALF_COUNT,
                                this.paragraphs.length
                            )
                        )
                    );

                // offset the scroll
                this.panel.scrollTop = this.panel.scrollTop - removedHeight;

                await this.updateSentenceLocations();

                this.disableScrollSync = false;
            }

            // if we're at the top of the screen
            else if (panel.scrollTop < pixelOffset) {
                // if we're at the start, don't do anything
                const isFirstParagraphReached = this.minActiveParagraphIndex === 0;
                if (isFirstParagraphReached) {
                    return;
                }

                // disable scroll sync while we move the scrolling around
                this.disableScrollSync = true;
                // close popover - it would be offset
                this.closePopover();

                // get the current sentence at the top of the active view, so we can keep it there after inserting height
                const topSentenceId = this.activeParagraphs[0].sentenceIds[0];

                // insert new paragraph, remove the ones outside of the viewport
                this.activeParagraphs = this.paragraphs
                    .slice(
                        Math.max(0, this.minActiveParagraphIndex - ACTIVE_PARAGRAPHS_HALF_COUNT),
                        this.minActiveParagraphIndex
                    )
                    .concat(this.activeParagraphs.slice(0, ACTIVE_PARAGRAPHS_HALF_COUNT));

                // compensate the scroll by the size of what we inserted, so that the user keeps looking at the same
                // sentence he was
                this.panel.scrollTop = this.panel.scrollTop + this.getSentenceElement(topSentenceId).offsetTop;

                await this.updateSentenceLocations();

                this.disableScrollSync = false;
            }
        },
        async scrollToSentence(id: number, progressionRatio = 0) {
            if (!id || this.paragraphs.length === 0) {
                return;
            }

            // this might happen on initialization - when we setup the state and try to scroll to the saved location,
            // this method can be called before the component is fully initialized. Adding this condition defers the
            // scrolling to after that.
            if (this.sentenceLocations.length === 0) {
                await this.updateSentenceLocations();
            }

            this.disableScrollSync = true;

            // if the sentence isn't in the active view, change activeParagraphs so they are centered on that sentence
            const isSentenceDisplayed = this.activeParagraphs
                .reduce((acc, p) => [...acc, ...p.sentenceIds], [] as number[])
                .includes(id);
            if (!isSentenceDisplayed) {
                // close popover - it would be offset
                this.closePopover();

                const paragraphIndexContainingId = this.paragraphs.findIndex((p: Paragraph) =>
                    p.sentenceIds.includes(id)
                );

                if (paragraphIndexContainingId === -1) {
                    return notifyError("Couldn't find the correct paragraph");
                }

                this.activeParagraphs = this.paragraphs.slice(
                    Math.max(0, paragraphIndexContainingId - ACTIVE_PARAGRAPHS_HALF_COUNT),
                    Math.min(this.paragraphs.length - 1, paragraphIndexContainingId + ACTIVE_PARAGRAPHS_HALF_COUNT)
                );

                await this.updateSentenceLocations();
            }

            // get next sentence to compute progression
            const sentenceLocation = find(["id", id], this.sentenceLocations) as SentenceLocation;
            const sentenceHeight = this.getNextSentenceScrollTop(id) - sentenceLocation.scrollTop;

            // scroll the panel to correct height
            this.panel.scrollTop = Math.max(
                this.getSentenceElement(id).offsetTop + progressionRatio * sentenceHeight - SYNC_HEIGHT,
                0
            );

            // if we don't add this, there can be a weird sliding effect, when the user doesn't do anything but the view
            // is very slowly scrolling to the bottom of the current sentence and then stops. I think it's because here
            // we disableScrollSync but are scrolling, so scroll events are fired and ignored in the handler, but if we
            // immediately turn on scrollSync again some scroll events have been fired and not yet ignored by the handler
            // - when they reach the handler it's not disabled anymore so they are synced while they should be ignored.
            await forceNextTick();

            this.disableScrollSync = false;
        },
        toggleEditedSentence(sentenceId: number | null) {
            this.$store.dispatch(ActionTypes.setEditedSentence, sentenceId);
        },
        async updateSentenceLocations() {
            this.sentenceLocations = [];

            const sentenceLocations: SentenceLocation[] = [];

            await this.$nextTick();

            this.activeParagraphs.forEach((paragraph: Paragraph, pIndex: number) => {
                paragraph.sentenceIds.forEach((id: number, sIndex: number) => {
                    const isFirstSentence = pIndex === 0 && sIndex === 0;
                    const isLastSentence =
                        pIndex === this.activeParagraphs.length - 1 && sIndex === paragraph.sentenceIds.length - 1;

                    let scrollTop;
                    if (isFirstSentence) {
                        scrollTop = 0;
                    } else if (isLastSentence) {
                        scrollTop = this.panel.scrollHeight;
                    } else {
                        scrollTop = this.getSentenceElement(id).offsetTop;
                    }

                    sentenceLocations.push({
                        scrollTop,
                        id: id,
                    });
                });
            });

            this.sentenceLocations = sentenceLocations;
        },
    },
});
