import { getDefaultState } from "@/store";
import {
    forceNextTick,
    getDatetimeString,
    getMomentFromDatetimeString,
    logProfileElapsed,
    logProfileStart,
} from "@/utils/helpers";
import { filter, flow, identity, map, reduce, sortBy } from "lodash/fp";
import { Sentence, Sentences, State } from "@/store/types";
import { parseSections } from "@/utils/parseDocument";

export const saveState = (state: State) => {
    // the undo/redo list only grows with time - don't save it in localstorage
    const { undoRedo, ...cleanedState } = state;

    localStorage.state = JSON.stringify(cleanedState);
};

export const getClosestSentenceId = (state: State, sentenceId: number, isForward: boolean) => {
    let nextSentenceId: number | null = null;

    const step = isForward ? 1 : -1;

    for (let id = sentenceId + step; 0 < id && id <= state.maxId; id = id + step) {
        // skip empty sentences
        if (state.sentences[id].original === "") {
            continue;
        }

        nextSentenceId = id;
        break;
    }

    return nextSentenceId;
};

export const resetStateDisplayData = (state: State) => {
    state.editedSentenceId = null;
    state.splashMessage = "";

    // reset display data nested in the Sentences object
    Object.keys(state.sentences).forEach((id: string) => {
        const sentence = state.sentences[Number(id)];

        state.sentences[Number(id)].display = {
            ...sentence.display,
            isBeingDeepLTranslated: false,
            isBeingHovered: false,
            isBeingEdited: false,
        };
    });
};

export const loadStateFromBackup = (state: State, backupState: State) => {
    // this is for migration purpose - if we add new config data, we want it to be merged with the previous
    // version backup data, not to be fully overwritten
    const { config, display, timer, ...backupStateWithoutConfig } = backupState;

    const stateToAssign: State = {
        ...getDefaultState(),
        config: {
            ...state.config,
            ...config,
        },
        display: {
            ...state.display,
            ...display,
        },
        timer: {
            ...state.timer,
            ...timer,
        },
        ...backupStateWithoutConfig,
    };

    // @todo: this is to migrate previous state pattern. Once the state has been updated, we can delete
    // migrate comments into their own separate key
    if (!stateToAssign.comments) {
        Object.keys(stateToAssign.sentences).forEach(id => {
            stateToAssign.comments[Number(id)] = {
                id: Number(id),
                // @ts-ignore
                text: stateToAssign.sentences[Number(id)].comment || "",
                updatedAt: getDatetimeString(),
            };
            // @ts-ignore
            delete stateToAssign.sentences[Number(id)].comment;
        });
    }
    // migrate sections into the state
    stateToAssign.sections = parseSections(stateToAssign.paragraphs, stateToAssign.sentences);
    // migrate paragraphs
    if (
        stateToAssign.paragraphs &&
        stateToAssign.paragraphs.length > 0 &&
        typeof stateToAssign.paragraphs[0].index === "undefined"
    ) {
        stateToAssign.paragraphs = stateToAssign.paragraphs.map((p, index) => ({ ...p, index }));
    }
    // migrate currentLocationId
    // @ts-ignore
    if (typeof stateToAssign.currentLocationId !== "undefined") {
        // @ts-ignore
        stateToAssign.display.currentLocationId = stateToAssign.currentLocationId;
        // @ts-ignore
        delete stateToAssign.currentLocationId;
    }

    Object.assign(state, stateToAssign);

    resetStateDisplayData(state);
};

export const getSecondsElapsed = (sentences: Sentences) => {
    let seconds = 0;

    flow(
        map((s: Sentence) => s.translatedAt),
        filter(identity),
        sortBy(identity),
        reduce((acc: string, translatedAt: string) => {
            if (!acc) {
                return translatedAt;
            }

            const diffInSeconds = Math.abs(
                getMomentFromDatetimeString(acc).diff(getMomentFromDatetimeString(translatedAt), "seconds")
            );

            // If two consecutive edits have been done at more than '$threshold' seconds apart, consider there
            // was a break between them and don't add the difference to our total time worked.
            const breakThresholdInSeconds = 15 * 60;
            const isLessThanThreshold = diffInSeconds < breakThresholdInSeconds;
            if (isLessThanThreshold) {
                seconds += diffInSeconds;
            }

            return translatedAt;
        }, "")
    )(sentences);

    return seconds;
};
