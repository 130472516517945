import axios, { AxiosRequestConfig } from "axios";

const baseApiUrl = process.env.VUE_APP_API_URL + "/api";

export async function get(path: string, options?: AxiosRequestConfig) {
    return axios.get(baseApiUrl + path, options);
}

export async function post(path: string, data?: any, options?: AxiosRequestConfig) {
    return axios.post(baseApiUrl + path, data, options);
}

export async function put(path: string, data?: any, options?: AxiosRequestConfig) {
    return axios.put(baseApiUrl + path, data, options);
}

export default { get, post, put };
