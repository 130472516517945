
import Vue from "vue";
import { mapGetters } from "vuex";

import { LangResourceGetter, langResources } from "@/utils/popover";

type Position = {
    top: string;
    left: string;
};

const HEIGHT = 41;
const WIDTH = 160;

export default Vue.extend({
    props: {
        left: Number,
        maxX: Number,
        open: Boolean,
        text: String,
        top: Number,
        translated: Boolean,
    },
    computed: {
        ...mapGetters(["config"]),
        lang(): string {
            return this.translated ? this.config.targetLang : this.config.sourceLang;
        },
        links(): { [key in keyof LangResourceGetter]: string } {
            return {
                definition: langResources[this.lang].definition(this.text),
                synonym: langResources[this.lang].synonym(this.text),
                translation: langResources[this.lang].translation(this.text, this.otherLang),
            };
        },
        otherLang(): string {
            return this.translated ? this.config.sourceLang : this.config.targetLang;
        },
        style(): Position {
            if (!this.open) {
                return {
                    top: "0",
                    left: "0",
                };
            }

            // prevent overflowing on the right
            const left = Math.min(
                this.maxX - WIDTH - 15, // pad right for possible scroll bar
                this.left
            );

            // the constants are for cosmetic offset
            return {
                top: this.top - HEIGHT - 8 + "px",
                left: left - 2 + "px",
            };
        },
    },
});
