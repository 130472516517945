import { Html5Entities } from "html-entities";
import Vue from "vue";

import { forceNextTick, getDatetimeString, getMomentFromDatetimeString } from "@/utils/helpers";
import { ActionTypes, MutationTypes } from "@/store";
import { saveState } from "@/utils/store";
import moment from "moment";
import { cloneDeep } from "lodash/fp";
import { Context, State, Timer } from "@/store/types";
import { escapeRegExp } from "lodash";

enum actionTypes {
    setSplashMessage = "setSplashMessage",
    setTimerMode = "setTimerMode",
    tickTimer = "tickTimer",
    toggleConfigKey = "toggleConfigKey",
}

enum mutationTypes {
    scrollToLocation = "scrollToLocation",
    search = "search",
    setConfig = "setConfig",
    setDisplay = "setDisplay",
    setProofreadFlag = "setProofreadFlag",
    setSplashMessage = "setSplashMessage",
    setName = "setName",
    setTimerMode = "setTimerMode",
    tickTimer = "tickTimer",
    toggleProjectModal = "toggleProjectModal",
    toggleWelcomeModal = "toggleWelcomeModal",
}

const actions = {
    async [actionTypes.setSplashMessage](context: Context, message = "") {
        context.commit(MutationTypes.setSplashMessage, message);

        if (message) {
            await forceNextTick();
        }
    },
    [actionTypes.setTimerMode](context: Context, mode: State["timer"]["mode"]) {
        context.commit(MutationTypes.setTimerMode, mode);
    },
    [actionTypes.tickTimer](context: Context) {
        const {
            state: { timer },
        } = context;

        const now = moment();

        const lastUpdatedAt = getDatetimeString(now);

        const timeSpent = cloneDeep(timer.timeSpent);

        // update time spent if necessary
        const timeSinceLastUpdate = Math.abs(getMomentFromDatetimeString(timer.lastUpdatedAt).diff(now, "seconds"));
        // If two consecutive edits have been done at more than '$threshold' seconds apart, consider there
        // was a break between them and don't add the difference to our total time worked.
        const breakThresholdInSeconds = 10 * 60;
        if (timeSinceLastUpdate < breakThresholdInSeconds) {
            timeSpent[timer.mode] += timeSinceLastUpdate;
        }

        context.commit(MutationTypes.tickTimer, { lastUpdatedAt, timeSpent });
    },
    async [actionTypes.toggleConfigKey](context: Context, configKey: keyof State["config"]) {
        const { state } = context;

        context.commit(MutationTypes.setConfig, {
            [configKey]: !state.config[configKey],
        });

        // if we turn on auto deepL translate and the sentence editor is open, trigger translate
        if (configKey === "autoDeepLTranslate" && state.config.autoDeepLTranslate && state.editedSentenceId) {
            await context.dispatch(ActionTypes.deepLAutoTranslateBatch, state.editedSentenceId);
        }
    },
};

const mutations = {
    [mutationTypes.toggleProjectModal](state: State, isProjectModalOpen: boolean) {
        state.display = {
            ...state.display,
            isProjectModalOpen: isProjectModalOpen,
        };

        saveState(state);
    },
    [mutationTypes.toggleWelcomeModal](state: State, isWelcomeModalOpen: boolean) {
        state.display = {
            ...state.display,
            isWelcomeModalOpen,
        };
    },
    [mutationTypes.scrollToLocation](state: State, scrollTo: State["display"]["scrollTo"]) {
        // some component just need to have the current sentence, they don't care about progression within a sentence
        // we don't want to trigger a rerender of them on each scroll event, so we plug them to 'currentLocationId' and
        // only change that value when the sentence changes
        if (state.display.currentLocationId !== scrollTo.id) {
            Vue.set(state.display, "currentLocationId", scrollTo.id);
        }

        state.display.scrollTo = { ...scrollTo };
    },
    [mutationTypes.search](state: State, { isSource, searchString }: { isSource: boolean; searchString: string }) {
        const matchIds: number[] = [];

        if (searchString.trim() === "") {
            if (isSource) {
                Vue.set(state.display, "search", {
                    ...state.display.search,
                    sourceTerm: searchString,
                    sourceMatchIds: matchIds,
                });
            } else {
                Vue.set(state.display, "search", {
                    ...state.display.search,
                    targetTerm: searchString,
                    targetMatchIds: matchIds,
                });
            }

            return;
        }

        const searchRegex = new RegExp(escapeRegExp(searchString.trim()), "i");

        Object.keys(state.sentences)
            .sort()
            .forEach((id) => {
                const text = state.sentences[Number(id)][isSource ? "original" : "translated"];

                if (Html5Entities.decode(text).match(searchRegex)) {
                    matchIds.push(Number(id));
                }
            });

        if (isSource) {
            Vue.set(state.display, "search", {
                ...state.display.search,
                sourceTerm: searchString,
                sourceMatchIds: matchIds,
            });
        } else {
            Vue.set(state.display, "search", {
                ...state.display.search,
                targetTerm: searchString,
                targetMatchIds: matchIds,
            });
        }
    },
    [mutationTypes.setConfig](state: State, newConfig: Partial<State["config"]>) {
        state.config = {
            ...state.config,
            ...newConfig,
        };

        saveState(state);
    },
    [mutationTypes.setDisplay](state: State, display: Partial<State["display"]>) {
        state.display = {
            ...state.display,
            ...display,
        };

        saveState(state);
    },
    [mutationTypes.setProofreadFlag](state: State, id: number | null) {
        // clean previous flag
        const previousId = state.config.proofreadFlagId;
        if (previousId) {
            state.sentences[previousId] = {
                ...state.sentences[previousId],
                isProofreadFlagged: false,
            };
        }

        state.config.proofreadFlagId = id;

        // update new flag
        if (id) {
            state.sentences[id] = {
                ...state.sentences[id],
                isProofreadFlagged: true,
            };
        }

        saveState(state);
    },
    [mutationTypes.setSplashMessage](state: State, msg: string) {
        state.splashMessage = msg;
    },
    [mutationTypes.setName](state: State, name: string) {
        state.name = name;

        saveState(state);
    },
    [mutationTypes.setTimerMode](state: State, mode: Timer["mode"]) {
        state.timer.mode = mode;

        saveState(state);
    },
    [mutationTypes.tickTimer](state: State, { lastUpdatedAt, timeSpent }: Pick<Timer, "lastUpdatedAt" | "timeSpent">) {
        // we purposefully update only the timer's property (instead of replacing the whole timer object)
        // to make it more efficient and only update the small component that depends on it - this mutation
        // is going to be ran a lot
        state.timer.lastUpdatedAt = lastUpdatedAt;
        state.timer.timeSpent = timeSpent;
    },
};

const getters = {
    canExportState: () => process.env.VUE_APP_CAN_EXPORT_STATE !== "false",
    config: (state: State) => state.config,
    display: (state: State) => state.display,
    filename: (state: State) => state.filename,
    isDebug: () => process.env.VUE_APP_DEBUG === "true",
    name: (state: State) => state.name,
    splashMessage: (state: State) => state.splashMessage,
    timer: (state: State) => state.timer,
    timerMode: (state: State) => state.timer.mode,
    projectId: (state: State) => state.projectId,
};

export default {
    actions,
    actionTypes,
    getters,
    mutations,
    mutationTypes,
};
