
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { Comment, Section, Sentence } from "@/store/types";
import { MutationTypes } from "@/store";

export default Vue.extend({
    props: {
        section: {
            required: true,
            type: Object as PropType<Section>,
        },
    },
    computed: {
        ...mapGetters(["comments", "config", "display", "sentences"]),
        commentCount(): number {
            return this.sectionComments.filter(comment => !!comment.text).length;
        },
        currentLocationProgression(): number {
            if (!this.hasCurrentLocation) {
                return 0;
            }

            const translatedSentencesCount = this.section.sentenceIds.filter(id => id <= this.display.currentLocationId)
                .length;

            return (translatedSentencesCount * 100) / this.sectionSentencesCount;
        },
        displaySectionWordsCount(): string {
            if (this.sectionWordsCount < 1000) {
                return String(this.sectionWordsCount);
            }

            return Math.round(this.sectionWordsCount / 1000) + "k";
        },
        hasCurrentLocation(): boolean {
            return this.section.sentenceIds.includes(this.display.currentLocationId);
        },
        proofreadProgression(): number {
            const proofreadSentencesCount = this.section.sentenceIds.filter(id => id < this.config.proofreadFlagId)
                .length;

            return (proofreadSentencesCount * 100) / this.sectionSentencesCount || 0;
        },
        sectionComments(): Comment[] {
            return this.section.sentenceIds.map(id => this.comments[id]);
        },
        sectionSentences(): Sentence[] {
            return this.section.sentenceIds.map(id => this.sentences[id]);
        },
        sectionSentencesCount(): number {
            return this.sectionSentences.length;
        },
        sectionWordsCount(): number {
            return this.sectionSentences.flatMap(s => s.original.split(/\s+/)).length;
        },
        translatedProgression(): number {
            const translatedWordsCount = this.sectionSentences.filter(sentence => sentence.hasBeenTranslated).length;

            return (translatedWordsCount * 100) / this.sectionSentencesCount || 0;
        },
    },
    methods: {
        handleScrollTo(id: number) {
            this.$store.commit(MutationTypes.scrollToLocation, { id });
        },
    },
});
