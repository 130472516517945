
import Vue from "vue";
import { mapGetters } from "vuex";
import { ActionTypes } from "@/store";
import { State } from "@/store/types";

const sourceLangs = [
    { value: "BG", text: "Bulgarian" },
    { value: "CS", text: "Czech" },
    { value: "DA", text: "Danish" },
    { value: "DE", text: "German" },
    { value: "EL", text: "Greek" },
    { value: "EN", text: "English" },
    { value: "ES", text: "Spanish" },
    { value: "ET", text: "Estonian" },
    { value: "FI", text: "Finnish" },
    { value: "FR", text: "French" },
    { value: "HU", text: "Hungarian" },
    { value: "IT", text: "Italian" },
    { value: "JA", text: "Japanese" },
    { value: "LT", text: "Lithuanian" },
    { value: "LV", text: "Latvian" },
    { value: "NL", text: "Dutch" },
    { value: "PL", text: "Polish" },
    { value: "PT", text: "Portuguese" },
    { value: "RO", text: "Romanian" },
    { value: "RU", text: "Russian" },
    { value: "SK", text: "Slovak" },
    { value: "SL", text: "Slovenian" },
    { value: "SV", text: "Swedish" },
    { value: "ZH", text: "Chinese" },
];

const targetLangs = [
    { value: "BG", text: "Bulgarian" },
    { value: "CS", text: "Czech" },
    { value: "DA", text: "Danish" },
    { value: "DE", text: "German" },
    { value: "EL", text: "Greek" },
    { value: "EN-GB", text: "English (British)" },
    { value: "EN-US", text: "English (American)" },
    { value: "ES", text: "Spanish" },
    { value: "ET", text: "Estonian" },
    { value: "FI", text: "Finnish" },
    { value: "FR", text: "French" },
    { value: "HU", text: "Hungarian" },
    { value: "IT", text: "Italian" },
    { value: "JA", text: "Japanese" },
    { value: "LT", text: "Lithuanian" },
    { value: "LV", text: "Latvian" },
    { value: "NL", text: "Dutch" },
    { value: "PL", text: "Polish" },
    { value: "PT-PT", text: "Portuguese (all except Brazilian)" },
    { value: "PT-BR", text: "Portuguese (Brazilian)" },
    { value: "RO", text: "Romanian" },
    { value: "RU", text: "Russian" },
    { value: "SK", text: "Slovak" },
    { value: "SL", text: "Slovenian" },
    { value: "SV", text: "Swedish" },
    { value: "ZH", text: "Chinese" },
];

type Form = {
    name: string;
    sourceLang: string;
    targetLang: string;
};

const getDefaultForm = (config: State["config"], name: string): Form => ({
    name,
    sourceLang: config.sourceLang,
    targetLang: config.targetLang,
});

export default Vue.extend({
    props: {
        open: Boolean,
    },
    data() {
        const config = this.$store.getters.config;
        const name = this.$store.getters.name;
        const form: Form = getDefaultForm(config, name);

        return {
            form,
            sourceLangs,
            targetLangs,
        };
    },
    computed: {
        ...mapGetters(["config", "name"]),
        errorMsg(): string {
            if (this.form.sourceLang === this.form.targetLang) {
                return "The source and target language must be different.";
            }

            return "";
        },
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        handleShown() {
            // reset form data
            this.form = getDefaultForm(this.config, this.name);
        },
        handleSubmit() {
            if (this.errorMsg) {
                return;
            }

            this.$store.dispatch(ActionTypes.updateProject, this.form);

            this.handleClose();
        },
    },
});
