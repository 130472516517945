
import Vue from "vue";
import { ActionTypes } from "@/store";
import MailchimpSignup from "@/components/UI/MailchimpSignup.vue";

const supportedTypes = ["HTML", "EPUB", "MOBI", "DOCX"];

export default Vue.extend({
    components: { MailchimpSignup },
    props: {
        visible: Boolean,
    },
    data() {
        return {
            supportedTypes,
        };
    },
    computed: {
        // obfuscate email address by filling it in js
        emailAddress() {
            return ("robjoeinjoe" + "@transljoe" + "ateabook.com").replace(/joe/g, "");
        },
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        handleUpload() {
            return alert("Uploading your document is disabled for now - the beta is still a work in progress.");

            (this.$refs.fileDocument as HTMLElement).click();
        },
        async loadDocument(event: Event) {
            const files = (event?.target as HTMLInputElement)?.files;
            if (!files) {
                return;
            }

            const file = files[0];
            if (!file) {
                return;
            }

            const fileType = file.type;
            const filename = file.name;

            if (fileType === "application/pdf") {
                return alert("PDF are not supported");
            }

            // reset field, so that the user can try reuploading the exact same file
            (this.$refs.fileDocument as HTMLInputElement).value = "";

            await this.$store.dispatch(ActionTypes.createProject, {
                file,
                filename,
                fileType,
            });
        },
        showExample() {
            this.$store.dispatch(ActionTypes.showExample);
        },
    },
});
