
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";

import { secondsToDisplayedTime } from "@/utils/helpers";
import { Timer } from "@/store/types";

export default Vue.extend({
    props: {
        percentDone: {
            required: true,
            type: Number
        },
        mode: {
            required: true,
            type: String as PropType<Timer["mode"]>
        }
    },
    computed: {
        ...mapGetters(["config", "timer"]),
        estimatedTotalTime(): string {
            const seconds = (this.timeSpent * 100) / this.percentDone;

            return secondsToDisplayedTime(seconds);
        },
        estimatedRemainingTime(): string {
            const secondsRemaining = (this.timeSpent * 100) / this.percentDone - this.timeSpent;

            return secondsToDisplayedTime(secondsRemaining);
        },
        isProofreading(): boolean {
            return this.mode === "proofread" && this.timer.mode === this.mode;
        },
        isTranslating(): boolean {
            return this.mode === "translation" && this.timer.mode === this.mode;
        },
        remainingTooltip(): string {
            if (!this.timeSpent || !this.percentDone || this.percentDone < 1) {
                return "Learning speed...";
            }

            return `Remaining: ${this.estimatedRemainingTime} (${this.estimatedTotalTime} total)`;
        },
        timeSpent(): number {
            return this.timer.timeSpent[this.mode];
        }
    },
    methods: {
        secondsToDisplayedTime,
    }
});
