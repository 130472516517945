import { Comments, Paragraph, Section, Sentence, Sentences } from "@/store/types";
import { removeHtmlTags } from "@/utils/helpers";

const getTemplatePlaceholder = (id: number) => {
    return `[#${id}#]`;
};

export const getTranslatedHtml = (templateHtml: string, sentences: Sentences): string => {
    return templateHtml.replace(/\[#(\d+)#]/g, (match, sentenceId) => {
        const sentence = sentences[sentenceId];
        if (!sentence) {
            return match;
        }

        return sentence.translated;
    });
};

export type SplitSurroundingTags = {
    closingTags: string;
    content: string;
    openingTags: string;
};

/**
 * Tries to remove surrounding tags that do not add anything, to make the text to edit simpler. For ex:
 * "<i>My sentence.</i>" => "My sentence."
 *
 * It tries to do it intelligently, not removing if not necessary "<i>a</i> big <i>tag</i>" shouldn't strip the outward
 * "<i>" etc. Check the tests to see all handled cases.
 */
export const splitSurroundingTags = (text: string): SplitSurroundingTags => {
    const match = text.match(
        /^(?<open><(?<tag>[^> ]+(?=[> ]))[^>]*>)(?![\s\S]*<\/\k<tag>\s*>(?!$))(?<content>[\s\S]*)(?<close><(?=\/\k<tag>\s*>)[^>]+>)$/i
    );

    if (!match || !match.groups) {
        return {
            closingTags: "",
            content: text,
            openingTags: "",
        };
    }

    const {
        groups: { open, content, close },
    } = match;

    const { openingTags: innerOpen, content: innerContent, closingTags: innerClose } = splitSurroundingTags(content);

    return {
        openingTags: open + innerOpen,
        content: innerContent,
        closingTags: innerClose + close,
    };
};

const splitSentences = (text: string) => text.split(/(?<=\.\.\.|[.!?])\s+/);

export const defaultSentenceDisplay: Sentence["display"] = {
    isBeingDeepLTranslated: false,
    isBeingEdited: false,
    isBeingHovered: false,
};

export const parseDocument = (originalHtml: string) => {
    const paragraphs: Paragraph[] = [];
    const sentences: Sentences = {};
    const comments: Comments = {};

    // matches opening tag + content ("<h1 class="foo">Foo bar")
    const matchingRegex = /(?<fullTag><(?<tag>p|h[1-6]|tr|li)\b[^>]*>\s*)(?<content>(?:[^<]+|<(?!\/\k<tag>))+)/gi;

    let id = 0;
    let paragraphIndex = 0;
    const templateHtml = originalHtml.replaceAll(matchingRegex, (match, fullTag, tag, content) => {
        // filter nodes with no text (like img, etc)
        if (!removeHtmlTags(content).trim()) {
            return match;
        }

        const paragraphTag = ["tr", "li"].includes(tag) ? "p" : tag;

        // add a paragraph
        paragraphs.push({
            index: paragraphIndex++,
            tag: paragraphTag,
            sentenceIds: [],
        });

        // create the paragraph template
        const paragraphTemplatePlaceholders: string[] = [];

        // split the text in the node into sentences
        const sentenceStrings = splitSentences(content.replaceAll(/^\s+|\s+$/g, ""));

        sentenceStrings.forEach((sentenceString: string, index) => {
            id++;

            const { closingTags, content, openingTags } = splitSurroundingTags(sentenceString);

            // add the sentence to our document translation
            sentences[id] = {
                display: { ...defaultSentenceDisplay },
                id,
                isProofreadFlagged: false,
                original: content,
                paragraphPosition: index,
                translated: content,
            };

            // create empty comment - it needs to exist for reactivity reasons
            comments[id] = {
                id,
                text: "",
                updatedAt: null,
            };

            // add the sentence to our paragraph
            paragraphs[paragraphs.length - 1].sentenceIds.push(id);

            // add placeholder for that sentence in the template
            paragraphTemplatePlaceholders.push(openingTags + getTemplatePlaceholder(id) + closingTags);
        });

        return fullTag + paragraphTemplatePlaceholders.join(" ");
    });

    const sections = parseSections(paragraphs, sentences);

    return {
        comments,
        originalHtml,
        paragraphs,
        sections,
        sentences,
        templateHtml,
    };
};

export const parseSections = (paragraphs: Paragraph[], sentences: Sentences): Section[] => {
    const sections: Section[] = [];

    const tagCount = paragraphs.reduce((acc, p: Paragraph) => {
        acc[p.tag] = (acc[p.tag] || 0) + 1;

        return acc;
    }, {} as { [key: string]: number });

    const sectionHeadingTag = Object.keys(tagCount)
        // only keep h1, h2 etc tags
        .filter(tag => /h/i.test(tag))
        // give priority to h1, then h2, etc
        .sort()
        // filter tags that occur only once - they might be the book title and aren't useful for navigation
        .filter(tag => tagCount[tag] > 1)[0];

    // add default first section
    sections.push({
        headerId: 1,
        sentenceIds: [],
        title: "(start)",
    });

    let sectionIndex = 0;
    let isPreviousSectionIndexAHeading = false;
    paragraphs.forEach(p => {
        const section = sections[sectionIndex];

        // our paragraph is the start of a section
        if (p.tag === sectionHeadingTag) {
            // if the previous index was a heading and we're still a heading, this paragraph is a continuation
            // of the same heading: merge it
            if (isPreviousSectionIndexAHeading) {
                return (sections[sectionIndex] = {
                    ...section,
                    sentenceIds: [...section.sentenceIds, ...p.sentenceIds],
                    title:
                        section.title + " " + p.sentenceIds.map(id => removeHtmlTags(sentences[id].original)).join(" "),
                });
            }

            // else we create a new section
            isPreviousSectionIndexAHeading = true;

            const newSection: Section = {
                headerId: p.sentenceIds[0],
                sentenceIds: p.sentenceIds,
                title: p.sentenceIds.map(id => removeHtmlTags(sentences[id].original)).join(" "),
            };

            return (sections[++sectionIndex] = newSection);
        }

        // add the sentence id to the section
        sections[sectionIndex].sentenceIds = [...sections[sectionIndex].sentenceIds, ...p.sentenceIds];

        isPreviousSectionIndexAHeading = false;
    });

    // if the first default section is empty, remove it
    if (sections[0].sentenceIds.length === 0) {
        sections.shift();
    }

    return sections;
};
