
import { Html5Entities } from "html-entities";
import Vue from "vue";
import { mapGetters } from "vuex";
import { ActionTypes, MutationTypes } from "@/store";

type TagMapping = {
    [index: number]: string;
};

type Data = {
    sentenceText: string;
    shouldBypassParsing: boolean;
    tagMapping: TagMapping;
};

const getDataFromText = (text: string | undefined): Data => {
    const data = {
        sentenceText: "",
        shouldBypassParsing: false,
        tagMapping: {} as TagMapping,
    };

    if (!text) {
        return data;
    }

    // if our placeholder delimiter '{}' is present in the original string, don't try to parse it to avoid conflict
    data.shouldBypassParsing = !!text.match(/[{}]/);

    data.sentenceText = text;

    // map and replace html tags
    if (!data.shouldBypassParsing) {
        let tagIndex = 0;
        data.sentenceText = text.replace(/<([^>]+)>/g, (tagFull, tagContent) => {
            tagIndex++;
            data.tagMapping[tagIndex] = tagFull;

            const parsedTag = /^(\/)?\s*(\w+)/.exec(tagContent);
            if (!parsedTag) {
                const errorMsg = "This tag couldn't be parsed successfully: " + tagFull;
                console.error(errorMsg);

                return tagFull;
            }

            const isClosing = parsedTag[1];
            const tagName = parsedTag[2];

            return "{" + tagIndex + "-" + (isClosing ? "/" : "") + tagName + "}";
        });

        // html decode the remaining text to edit it comfortably
        data.sentenceText = Html5Entities.decode(data.sentenceText);
    }

    return data;
};

export default Vue.extend({
    data() {
        const sentence = this.$store.getters.editedSentence;

        return getDataFromText(sentence?.translated);
    },
    watch: {
        // stay controlled by parent
        sentence(value) {
            Object.assign(this.$data, getDataFromText(value?.translated));

            // focus on textarea
            this.$nextTick(() => {
                if (value) {
                    // @ts-ignore - this works
                    this.$refs.textarea.focus();

                    // if the sentence has not been pre-translated we probably want to replace everything, select all
                    // the text
                    if (!value.hasBeenTranslated && !value.deepLTranslation) {
                        // @ts-ignore - this works
                        this.$refs.textarea.select();
                    }
                }
            });
        },
    },
    computed: {
        ...mapGetters({ sentence: "editedSentence" }),
        ...mapGetters(["comments", "config", "isDebug"]),
        hasComment(): boolean {
            return !!this.comments[this.sentence.id].text;
        },
        hasCustomTag(): boolean {
            return /[{}]/.test(this.originalTextTagMapped);
        },
        hasProofreadFlag(): boolean {
            return this.config.proofreadFlagId && this.config.proofreadFlagId === this.sentence.id;
        },
        htmlTranslatedResult(): string {
            if (this.shouldBypassParsing) {
                return this.sentenceText;
            }

            // html encode the content between tags
            let htmlText = this.sentenceText.replace(/(^|})([^{]+)({|$)/g, (fullMatch, opening, content, closing) => {
                return opening + Html5Entities.encode(content) + closing;
            });

            // replace tag placeholders by their actual value
            htmlText = htmlText.replace(/{(\d+)[^}]*}/g, (fullMatch, tagIndex) => {
                return this.tagMapping[tagIndex];
            });

            return htmlText;
        },
        isFirstSentenceInParagraph(): boolean {
            return this.sentence?.paragraphPosition === 0;
        },
        proofreadFlagLabel(): string {
            if (this.hasProofreadFlag) {
                return "Remove the proofread flag";
            }

            return "Mark proofread until here";
        },
        originalTextTagMapped(): string {
            const { sentenceText } = getDataFromText(this.sentence?.original);

            return sentenceText;
        },
    },
    methods: {
        handleCancel() {
            this.$emit("close");
        },
        handleComment(isAddingComment: boolean) {
            if (isAddingComment) {
                return this.$emit("add:comment");
            }

            this.$store.commit(MutationTypes.comment, {
                id: this.sentence.id,
                text: "",
            });
        },
        handleDeepLTranslate() {
            this.$store.dispatch(ActionTypes.translateSentenceDeepL, this.sentence.id);
        },
        handleNextSentence() {
            this.$store.dispatch(ActionTypes.editNextSentence);
        },
        handlePreviousSentence() {
            this.$store.dispatch(ActionTypes.editPreviousSentence);
        },
        handleSetProofreadFlag() {
            this.$store.commit(MutationTypes.setProofreadFlag, this.hasProofreadFlag ? null : this.sentence.id);
        },
        handleShowHtmlPreview() {
            this.$store.dispatch(ActionTypes.toggleConfigKey, "showHtmlPreview");
        },
        handleShowOriginalHtml() {
            this.$store.dispatch(ActionTypes.toggleConfigKey, "showOriginalHtml");
        },
        mergePrevious() {
            this.$store.dispatch(ActionTypes.mergePrevious, this.sentence.id);
        },
        restoreFrom() {
            this.$store.commit(MutationTypes.restoreSentence, this.sentence.id);
        },
        toggleEditedSentence(sentenceId: number | null) {
            this.$store.dispatch(ActionTypes.setEditedSentence, sentenceId);
        },
        updateSentence() {
            this.$store.dispatch(ActionTypes.updateSentence, {
                id: this.sentence.id,
                text: this.htmlTranslatedResult,
            });

            this.$emit("updated");

            this.handleNextSentence();
        },
    },
});
