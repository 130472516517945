import {
    forceNextTick,
    logProfileElapsed,
    logProfileStart,
    notifyError,
    percentTranslated,
    roundPercentage,
    sanitizeFilename,
} from "@/utils/helpers";
import {
    convertFile,
    downloadFile,
    extractHtmlFromHtmlz,
    MIMETYPE_HTMLZ,
    readFileAsText,
    uploadToS3,
} from "@/utils/formatConverter";
import JSZip from "jszip";
import db from "@/utils/db";
import { cloneDeep } from "lodash/fp";
import uuid from "uuid";
import moment from "moment";
import { ActionTypes, getDefaultState, MutationTypes } from "@/store";
import { loadStateFromBackup, resetStateDisplayData, saveState } from "@/utils/store";
import { Context, State } from "@/store/types";
import { getTranslatedHtml, parseDocument } from "@/utils/parseDocument";
import axios from "axios";
import api from "@/utils/api";

enum actionTypes {
    exportDocument = "exportDocument",
    exportState = "exportState",
    loadBackup = "loadBackup",
    createProject = "createProject",
    updateProject = "updateProject",
    loadState = "loadState",
    saveBackup = "saveBackup",
    showExample = "showExample",
}

enum mutationTypes {
    loadBackup = "loadBackup",
    parseAndLoadHtml = "parseAndLoadHtml",
}

const getCleanedStateForSaving = (state: State): State => {
    const cleanState = cloneDeep(state);
    cleanState.undoRedo = getDefaultState().undoRedo;

    return cleanState;
};

const pushProjectToApi = (state: State) => {
    return api.post("/projects", {
        serializedState: JSON.stringify(state),
    });
};

const actions = {
    async [actionTypes.exportDocument](context: Context, format: string): Promise<Blob | undefined> {
        const { state } = context;

        const translatedHtml = getTranslatedHtml(state.templateHtml, state.sentences);

        if (format === "html") {
            return new Blob([translatedHtml], { type: "text/html" });
        }

        try {
            await context.dispatch(ActionTypes.setSplashMessage, "Converting your file... (this might take a minute)");

            // generate the key to source htmlz file
            const filenameHtmlz = state.filename.replace(/\.[^.]+$/, "") + ".htmlz";
            const s3SourceKey = state.projectId + "/" + filenameHtmlz;

            // download the source htmlz
            const bucket = process.env.VUE_APP_AWS_BUCKET || "translateabook";
            const sourceHtmlzUrl = `https://${bucket}.s3.amazonaws.com/${encodeURIComponent(s3SourceKey)}`;
            const sourceHtmlz = await downloadFile(sourceHtmlzUrl);

            // replace the source index.html with the translated one
            const zip = new JSZip();
            await zip.loadAsync(sourceHtmlz);
            zip.remove("index.html");
            zip.file("index.html", translatedHtml);

            const translatedHtmlz = await zip.generateAsync({ type: "blob" });

            // upload the zipped htmlz file to s3
            const s3TargetKey = "translated_" + filenameHtmlz;
            const { resourceUrl: translatedHtmlzUrl } = await uploadToS3(
                s3TargetKey,
                MIMETYPE_HTMLZ,
                translatedHtmlz,
                state.projectId
            );

            if (format === "htmlz") {
                return translatedHtmlz;
            }

            return await convertFile(translatedHtmlzUrl, format);
        } catch (error) {
            notifyError(error);
            throw error;
        } finally {
            await context.dispatch(ActionTypes.setSplashMessage);
        }
    },
    [actionTypes.exportState](context: Context): string {
        return JSON.stringify(getCleanedStateForSaving(context.state));
    },
    async [actionTypes.loadBackup](context: Context, id: number) {
        const backup = await db.backups.get(id);

        if (!backup) {
            return alert("Could not find any backup for date id " + id);
        }

        await context.dispatch(ActionTypes.setSplashMessage, "Loading...");

        context.commit(MutationTypes.loadBackup, cloneDeep(backup.state));

        await context.dispatch(ActionTypes.setSplashMessage);
        context.commit(MutationTypes.toggleWelcomeModal, false);
    },
    async [actionTypes.createProject](
        context: Context,
        { file, filename, fileType }: { file: Blob | File; filename: string; fileType: string }
    ) {
        filename = sanitizeFilename(filename);

        // use our custom filetype if it's an HTMLZ file
        const extension = filename.match(/\.\w+$/) || [""];
        fileType = extension[0].toLowerCase() === ".htmlz" ? MIMETYPE_HTMLZ : fileType;

        const parseAndLoadHtml = async (html: string) => {
            context.commit(MutationTypes.parseAndLoadHtml, {
                filename,
                html,
                projectId: uuid.v4(),
            });

            await context.dispatch(ActionTypes.setSplashMessage, "Saving your project...");

            await pushProjectToApi(context.state);

            // make sure the welcome modal is closed - we just loaded a document and want to show it
            context.commit(MutationTypes.toggleWelcomeModal, false);

            // open the editor to the first sentence, so people understand how it works
            await context.dispatch(ActionTypes.setEditedSentence, context.state.sentences[1].id);

            // open the project settings modal
            context.commit(MutationTypes.toggleProjectModal, true);
        };

        try {
            // useful in dev - I don't want to upload the document everytime I load an html file to test features
            if (process.env.VUE_APP_BYPASS_UPLOAD && fileType === "text/html") {
                await context.dispatch(ActionTypes.setSplashMessage, "Parsing your file in browser...");

                await parseAndLoadHtml(await readFileAsText(file));

                return;
            }

            await context.dispatch(ActionTypes.setSplashMessage, "Saving your file...");

            // upload the original file to s3
            const { projectId, resourceUrl } = await uploadToS3(
                // we prefix the filename - that way if the original is already an htmlz, we can still convert it to
                // an htmlz through calibre to get a canonical version
                "original_" + filename,
                fileType,
                file
            );

            // if the file is already HTML, just load the file
            if (fileType === "text/html") {
                await context.dispatch(ActionTypes.setSplashMessage, "Parsing your file in browser...");

                await parseAndLoadHtml(await readFileAsText(file));

                return;
            }

            // convert and download the converted file
            await context.dispatch(ActionTypes.setSplashMessage, "Converting your file... (this might take a minute)");
            const convertedFile = await convertFile(resourceUrl);

            // upload the converted file to our S3 storage
            await context.dispatch(ActionTypes.setSplashMessage, "Saving your converted file...");
            const convertedFilename = filename.replace(/(?:\.\w+|)$/, ".htmlz");
            const { s3Key } = await uploadToS3(convertedFilename, MIMETYPE_HTMLZ, convertedFile, projectId);

            // extract the file to load as document
            await context.dispatch(ActionTypes.setSplashMessage, "Extracting the relevant parts...");
            const htmlFile = await extractHtmlFromHtmlz(s3Key);

            await context.dispatch(ActionTypes.setSplashMessage, "Final step: parsing your file in browser...");

            await parseAndLoadHtml(htmlFile);
        } catch (error) {
            notifyError(error);
            throw error;
        } finally {
            // close the splash message if anything went wrong
            await context.dispatch(ActionTypes.setSplashMessage);
        }
    },
    async [actionTypes.loadState](context: Context, stateFile: File) {
        const jsonState = await readFileAsText(stateFile);

        await context.dispatch(ActionTypes.setSplashMessage, "Loading...");

        context.commit(MutationTypes.loadBackup, JSON.parse(jsonState));

        await context.dispatch(ActionTypes.setSplashMessage);
    },

    async [actionTypes.saveBackup](context: Context, name: string) {
        const state = getCleanedStateForSaving(context.state);

        await db.backups.add({
            datetime: moment().format("YYYY-MM-DD HH:mm:ss"),
            name,
            percentTranslated: roundPercentage(percentTranslated(context.state.sentences)),
            state,
        });
    },
    async [actionTypes.showExample](context: Context) {
        await context.commit(ActionTypes.setSplashMessage, "Loading...");

        try {
            const { data } = await axios.get("/example_state.json");

            data.display.isExample = true;

            context.commit(MutationTypes.loadBackup, data);
        } catch (err) {
            notifyError("The example couldn't be loaded");
            return;
        } finally {
            context.commit(ActionTypes.setSplashMessage);
        }

        context.commit(MutationTypes.toggleWelcomeModal, false);

        console.log(context.state.sentences);

        // open the editor to the first sentence, so people understand how it works
        await context.dispatch(ActionTypes.setEditedSentence, context.state.sentences[1].id);
    },
    async [actionTypes.updateProject](
        context: Context,
        { name, sourceLang, targetLang }: { name: string; sourceLang: string; targetLang: string }
    ) {
        context.commit(MutationTypes.setName, name);
        context.commit(MutationTypes.setConfig, { sourceLang, targetLang });

        // save details to backend
        await api.put(`/projects/${context.state.projectId}`, {
            name,
            sourceLang,
            targetLang,
        });
    },
};

const mutations = {
    [mutationTypes.loadBackup](state: State, backupState: State) {
        loadStateFromBackup(state, backupState);

        saveState(state);
    },
    [mutationTypes.parseAndLoadHtml](
        state: State,
        { filename, html, projectId }: { filename: string; html: string; projectId: string }
    ) {
        const profiling = logProfileStart("parseAndLoadHtml");

        const parsedDocument = parseDocument(html);

        const maxId = Math.max(...Object.keys(parsedDocument.sentences).map((id) => Number(id)));

        Object.assign(state, {
            ...getDefaultState(),
            ...parsedDocument,
            filename,
            maxId,
            originalHtml: html,
            projectId,
        });

        resetStateDisplayData(state);

        saveState(state);

        logProfileElapsed(profiling);

        forceNextTick(() => logProfileElapsed(profiling, "next tick"));
    },
};

const getters = {
    paragraphs: (state: State) => state.paragraphs,
    sections: (state: State) => state.sections,
    templateHtml: (state: State) => state.templateHtml,
};

export default {
    actions,
    actionTypes,
    getters,
    mutations,
    mutationTypes,
};
