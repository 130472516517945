
import Vue from "vue";

import NavBar from "./components/NavBar/index.vue";
import SplashModal from "./components/UI/SplashModal.vue";
import Translation from "./components/Translation.vue";

export default Vue.extend({
    components: {
        NavBar,
        SplashModal,
        Translation,
    },
});
