import Vuex from "vuex";
import document from "@/store/actions/document";
import sentence from "@/store/actions/sentence";
import { loadStateFromBackup } from "@/utils/store";
import config from "@/store/actions/config";
import { cloneDeep } from "lodash/fp";
import { Comments, Paragraph, Section, Sentences, State } from "@/store/types";

const defaultState: State = {
    config: {
        autoDeepLTranslate: false,
        proofreadFlagId: null,
        showHtmlPreview: false,
        showOriginalHtml: false,
        sourceLang: "EN",
        targetLang: "FR",
    },
    comments: {} as Comments,
    display: {
        currentLocationId: 1,
        isExample: false,
        isShowingSearch: false,
        isProjectModalOpen: false,
        isWelcomeModalOpen: false,
        scrollTo: {
            id: 1,
            progressionRatio: 0,
        },
        search: {
            sourceTerm: "",
            sourceMatchIds: [] as number[],
            targetTerm: "",
            targetMatchIds: [] as number[],
        },
    },
    editedSentenceId: null as number | null,
    filename: "",
    maxId: 0,
    name: "",
    originalHtml: "",
    paragraphs: [] as Paragraph[],
    projectId: "",
    sections: [] as Section[],
    sentences: {} as Sentences,
    splashMessage: "",
    templateHtml: "",
    timer: {
        lastUpdatedAt: "",
        mode: "translation",
        timeSpent: {
            proofread: 0,
            translation: 0,
        },
    },
    undoRedo: {
        doneStack: [],
        undoneStack: [],
    },
};

export const getDefaultState = (): State => cloneDeep(defaultState);

export const ActionTypes = {
    ...config.actionTypes,
    ...document.actionTypes,
    ...sentence.actionTypes,
};
export type ActionTypes = typeof ActionTypes;

export const MutationTypes = {
    ...config.mutationTypes,
    ...document.mutationTypes,
    ...sentence.mutationTypes,
    initialiseStore: "initialiseStore",
};
export type MutationTypes = typeof MutationTypes;

const getStore = () => {
    return new Vuex.Store({
        actions: {
            ...config.actions,
            ...document.actions,
            ...sentence.actions,
        },
        state: getDefaultState(),
        getters: {
            ...config.getters,
            ...document.getters,
            ...sentence.getters,
        },
        mutations: {
            ...config.mutations,
            ...document.mutations,
            ...sentence.mutations,

            [MutationTypes.initialiseStore](state) {
                if (!localStorage.state) {
                    state.display.isWelcomeModalOpen = true;

                    return;
                }

                loadStateFromBackup(state, JSON.parse(localStorage.state));
            },
        },
        plugins: [
            (store) => {
                store.subscribe((mutation) => {
                    if (mutation.type === MutationTypes.tickTimer) {
                        return;
                    }

                    store.dispatch(ActionTypes.tickTimer);
                });
            },
        ],
    });
};

export default getStore;
