
import Vue from "vue";
import { MutationTypes } from "@/store";
import { FindReplaceForm } from "@/store/actions/sentence";

export default Vue.extend({
    props: {
        open: Boolean
    },
    data() {
        return {
            form: {
                find: "",
                isUsingRegex: false,
                replace: ""
            } as FindReplaceForm
        };
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        handleSubmit() {
            this.$store.commit(MutationTypes.findAndReplace, this.form);

            this.handleClose();
        }
    }
});
