import "mutationobserver-shim";
import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
import Meta from "vue-meta";
// @ts-ignore - no typing for this lib
import drag from "v-drag";
import Notifications from "vue-notification";

import "@babel/polyfill";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowDown,
    faArrowRight,
    faCheck,
    faExclamationTriangle,
    faFlag,
    faStopwatch,
    faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import getStore, { ActionTypes, MutationTypes } from "@/store";

import "./plugins/bootstrap-vue";

import App from "./App.vue";
import Vuex from "vuex";
import { forceNextTick } from "@/utils/helpers";

require("@/style/style.css");

library.add(faArrowDown, faArrowRight, faCheck, faExclamationTriangle, faFlag, faStopwatch, faUndo);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueObserveVisibility);
Vue.use(drag);
Vue.use(Meta);
Vue.use(Notifications);

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        Vue,
        dsn: "https://60a331f16b68468a868040f24a251744@o943593.ingest.sentry.io/5892500",
        integrations: [
            // this is used to add a list of ajax calls made in the app before an error is captured
            new Integrations.BrowserTracing({
                // only ajax calls made to urls matching those patterns will be saved
                tracingOrigins: ["localhost", "translateabook.com", /^\//],
            }),
        ],
        logErrors: true,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

new Vue({
    async mounted() {
        // make sure the whole UI is loaded before trying to display a splash message
        await forceNextTick();

        await this.$store.dispatch(ActionTypes.setSplashMessage, "Loading...");

        this.$store.commit(MutationTypes.initialiseStore);

        await this.$store.dispatch(ActionTypes.setSplashMessage);
    },
    metaInfo: () => ({
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: "Translate A Book",
    }),
    render: h => h(App),
    store: getStore(),
}).$mount("#app");
