/*
 * Initialize DB
 */
import Dexie from "dexie";
import { State } from "@/store/types";

class EditorDatabase extends Dexie {
    // Declare implicit table properties.
    // (just to inform Typescript. Instanciated by Dexie in stores() method)
    backups: Dexie.Table<IBackup, number>; // number = type of the primkey

    constructor() {
        super("EditorDatabase");
        this.version(1).stores({
            backups: "++id"
        });
        // The following line is needed if your typescript
        // is compiled using babel instead of tsc:
        this.backups = this.table("backups");
    }
}

export interface IBackup {
    id?: number;
    datetime: string;
    name: string;
    percentTranslated: number;
    state: State;
}

export default new EditorDatabase();
