
import Vue from "vue";
import { mapGetters } from "vuex";
import PersistentModal from "@/components/UI/PersistentModal.vue";

export default Vue.extend({
    components: { PersistentModal },
    computed: {
        ...mapGetters(["splashMessage"]),
        isVisible(): boolean {
            return !!this.splashMessage;
        }
    },
});
