
import Vue from "vue";
import { mapGetters } from "vuex";
import { MetaInfo } from "vue-meta/types/vue-meta";
// @ts-ignore - no typing
import VueFileToolbarMenu from "vue-file-toolbar-menu";

import db, { IBackup } from "@/utils/db";
import ProjectModal from "@/components/ProjectModal.vue";
import Progression from "./Progression.vue";
import { MIMETYPE_HTMLZ } from "@/utils/formatConverter";
import { downloadFileOnUserComputer, sanitizeFilename } from "@/utils/helpers";
import moment from "moment";
import { ActionTypes, MutationTypes } from "@/store";
import FindReplaceModal from "./FindReplaceModal.vue";
import WelcomeModal from "@/components/WelcomeModal.vue";

type DocumentFormat = {
    mimetype: string;
    value: string;
};

type DocumentFormats = { [key: string]: DocumentFormat };

const documentFormats: DocumentFormats = {
    docx: {
        mimetype: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        value: "docx",
    },
    epub: {
        mimetype: "application/epub+zip",
        value: "epub",
    },
    html: {
        mimetype: "text/html",
        value: "html",
    },
    htmlz: {
        mimetype: MIMETYPE_HTMLZ,
        value: "htmlz",
    },
    mobi: {
        mimetype: "application/x-mobipocket-ebook",
        value: "mobi",
    },
};

export default Vue.extend({
    components: { WelcomeModal, FindReplaceModal, ProjectModal, Progression, VueFileToolbarMenu },
    data() {
        return {
            backups: [] as IBackup[],
            documentFormats: Object.keys(documentFormats)
                .map((f: keyof DocumentFormats) => documentFormats[f])
                .sort(),
            isFindReplaceModalOpen: false,
        };
    },
    metaInfo(): MetaInfo {
        if (!this.name) {
            return {};
        }

        return {
            title: this.name,
        };
    },
    computed: {
        ...mapGetters([
            "canExportState",
            "canRedo",
            "canUndo",
            "config",
            "display",
            "filename",
            "name",
            "projectId",
            "templateHtml",
        ]),
        menus(): Object[] {
            const exportMenu = Object.keys(documentFormats)
                .sort()
                .map((df) => ({
                    text: `To ${documentFormats[df].value}`,
                    click: () => this.exportDocument(documentFormats[df].value),
                }));

            const menu: Object[] = [
                {
                    text: "File",
                    menu: [
                        {
                            text: "New project...",
                            click: () => this.toggleWelcomeModal(true),
                        },
                        {
                            text: "Save backup",
                            click: () => this.exportState(),
                        },
                        {
                            text: "Load backup",
                            click: () => (this.$refs.fileState as HTMLElement).click(),
                        },
                    ],
                },
                {
                    menu: [
                        {
                            click: () => this.toggleProjectModal(true),
                            html: "Name and language...",
                        },
                    ],
                    text: "Edit",
                },
                {
                    menu: [
                        {
                            click: (e: Event) => {
                                e.stopPropagation();
                                this.$store.commit(MutationTypes.setDisplay, {
                                    isShowingSearch: !this.display.isShowingSearch,
                                });
                            },
                            icon: this.display.isShowingSearch ? "check_box" : "check_box_outline_blank",
                            text: "Show search bar",
                        },
                        {
                            click: () => this.toggleFindReplaceModal(true),
                            text: "Find and replace...",
                        },
                    ],
                    menu_width: 200,
                    text: "Search",
                },
                {
                    text: "Export",
                    menu: exportMenu,
                },
                { is: "separator" },
                {
                    click: () => this.$store.commit(MutationTypes.undo),
                    disabled: !this.canUndo,
                    icon: "undo",
                    title: "Undo",
                },
                {
                    click: () => this.$store.commit(MutationTypes.redo),
                    disabled: !this.canRedo,
                    icon: "redo",
                    title: "Redo",
                },
                { is: "separator" },
                {
                    click: (e: Event) => {
                        e.stopPropagation();
                        this.toggleAutoDeepLTranslate();
                    },
                    icon: this.config.autoDeepLTranslate ? "check_box" : "check_box_outline_blank",
                    text: "Translate with AI",
                    title: "Translate with AI automatically",
                },
            ];

            if (!this.display.isExample) {
                menu.push({
                    click: () => this.exportState(),
                    html: "<button class='btn btn-primary btn-sm'>Backup</button>",
                    title: "Backup",
                });
            }

            return menu;
        },
    },
    methods: {
        async exportDocument(format: string) {
            const exportedDocument = await this.$store.dispatch(ActionTypes.exportDocument, format);

            const filenameWithoutExtension = sanitizeFilename(this.name || "") || this.filename.replace(/\.\w+$/, "");
            const filename = filenameWithoutExtension + "." + (this.config.targetLang || "translated") + "." + format;

            downloadFileOnUserComputer(exportedDocument, filename);
        },
        async exportState() {
            const jsonState = await this.$store.dispatch(ActionTypes.exportState);

            const filename =
                (sanitizeFilename(this.name || "") || "state") + "." + moment().format("YYYY-MM-DD_HH:mm:ss") + ".json";

            downloadFileOnUserComputer(jsonState, filename);
        },
        loadBackup(id: number) {
            this.$store.dispatch(ActionTypes.loadBackup, id);
        },
        loadState(event: Event) {
            const files = (event?.target as HTMLInputElement)?.files;
            if (!files) {
                return;
            }

            const file = files[0];

            this.$store.dispatch(ActionTypes.loadState, file);
        },
        async refreshBackups() {
            const availableBackups: IBackup[] = [];

            await db.backups
                .orderBy("id")
                .reverse()
                // extract the id and datetime only, to avoid having to load all saved backup strings in memory
                .each((backup) => {
                    const { id, datetime, name, percentTranslated } = backup;

                    // @ts-ignore - the id is never null
                    availableBackups.push({
                        id,
                        datetime,
                        name,
                        percentTranslated,
                    });
                });

            this.backups = availableBackups;
        },
        saveBackup() {
            const backupName = prompt("How should the backup be named?");

            if (backupName === null) {
                return;
            }

            this.$store.dispatch(ActionTypes.saveBackup, backupName);
        },
        toggleAutoDeepLTranslate() {
            this.$store.dispatch(ActionTypes.toggleConfigKey, "autoDeepLTranslate");
        },
        toggleFindReplaceModal(isOpen: boolean) {
            this.isFindReplaceModalOpen = isOpen;
        },
        toggleProjectModal(isOpen: boolean) {
            this.$store.commit(MutationTypes.toggleProjectModal, isOpen);
        },
        toggleWelcomeModal(isOpen: boolean) {
            this.$store.commit(MutationTypes.toggleWelcomeModal, isOpen);
        },
    },
});
