var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmpty),expression:"!isEmpty"}]},[(_vm.hasImbalancedTags)?_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Translation has different imbalanced tags'),expression:"'Translation has different imbalanced tags'"}],staticClass:"text-danger mr-1",attrs:{"icon":"exclamation-triangle"}}):_vm._e(),(_vm.hasComment)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.comment.text),expression:"comment.text"}],staticClass:"mr-1",attrs:{"variant":"warning","pill":""}},[_vm._v(" C ")]):_vm._e(),_c('span',{staticClass:"mr-1",class:{
            'border-bottom': _vm.showAsManuallyTranslated || _vm.showAsDeepLTranslated || _vm.showAsImbalanced,
            'border-success': _vm.showAsManuallyTranslated,
            'border-warning': _vm.showAsDeepLTranslated,
            'border-danger': _vm.showAsImbalanced,
            deepLtranslated: !_vm.source && _vm.isBeingDeepLTranslated,
            edited: _vm.isBeingEdited,
            hovered: _vm.isHovered || _vm.hasComment,
        },domProps:{"innerHTML":_vm._s(_vm.text)},on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}}),(_vm.sentence.isProofreadFlagged)?_c('font-awesome-icon',{staticClass:"mr-1 text-primary",attrs:{"icon":"flag"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }