import moment, { Moment } from "moment";
import { filter } from "lodash/fp";
import Vue from "vue";
import { Sentence, Sentences } from "@/store/types";

const getClosingTags = (text: string): string[] => {
    return [...text.matchAll(/<\/([^>\s]+)/g)].map(m => m[1].toLowerCase());
};

const getOpeningTags = (text: string): string[] => {
    const openingTags = [...text.matchAll(/<(?!\/)([^>\s]+)/g)].map(m => m[1].toLowerCase());

    const tagsWithoutNeedforClosing = ["br", "hr", "img"];

    return openingTags.filter(t => !tagsWithoutNeedforClosing.includes(t));
};

const getImbalancedTags = (text: string) => {
    const openingTags = getOpeningTags(text);
    const closingTags = getClosingTags(text);

    return {
        openingTags: openingTags.filter(t => !closingTags.includes(t)),
        closingTags: closingTags.filter(t => !openingTags.includes(t)),
    };
};

/**
 * Check if the imbalance of tags is different in original and translated text. it's ok to have imbalanced tags within
 * a sentence, for ex we can have:
 * "<i>A quick fox. Jumped over there</i>"
 * Here the first sentence will have only a "<i>", second only a "</i>".
 *
 * It's also ok to have a different number of balanced tags in original and translated texts:
 * "The fox <i>ate</i> quickly" => "Le renard <i>a</i> rapidement <i>mangé</i>".
 *
 * But if we have a different number of imbalanced tags, something is fishy and might be an auto-translate artefact:
 * "<i>A quick fox. Jumped over there</i>" => "<i>A quick fox.<i>Jumped over there</i>"
 *
 * @param sentence
 */
export const hasSentenceImbalancedTags = (sentence: Sentence): boolean => {
    const imbalancedOriginal = getImbalancedTags(sentence.original);
    const imbalancedTranslated = getImbalancedTags(sentence.translated);

    return (
        imbalancedOriginal.closingTags.length !== imbalancedTranslated.closingTags.length ||
        imbalancedOriginal.openingTags.length !== imbalancedTranslated.openingTags.length
    );
};

export const getDatetimeString = (date?: Moment): string => (date || moment()).format("YYYY-MM-DD HH:mm:ss");

export const downloadFileOnUserComputer = (file: string | Blob, filename: string) => {
    const link = document.createElement("a");

    if (typeof file === "string") {
        link.href = "data:text/plain;charset=utf-8," + encodeURIComponent(file);
    } else {
        link.href = URL.createObjectURL(file);
    }

    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
};

export const getPercentage = (numerator: number, denominator?: number): number => {
    return (numerator / (denominator || 1)) * 100;
};

export const percentTranslated = (sentences: Sentences): number => {
    const translatedCount = filter("hasBeenTranslated", sentences).length;
    const sentencesLength = Object.keys(sentences).length;

    return getPercentage(translatedCount, sentencesLength);
};

export const removeHtmlTags = (html: string): string => {
    return html.replaceAll(/<[^>]*>/g, "");
};

export const roundPercentage = (percentage: number): number => {
    return Math.round(percentage * 10) / 10;
};

export const htmlToPlainText = (html: string | null): string => {
    if (html === null || html === undefined) {
        return "";
    }

    // replace all <br> with spaces
    let plainText = html.replaceAll(/<br[^>]*>/gi, " ");
    // remove all html tags
    plainText = removeHtmlTags(plainText);

    return plainText;
};

export const getMomentFromDatetimeString = (datetime: string) => {
    return moment(datetime, "YYYY-MM-DD HH:mm:ss");
};

export const secondsToDisplayedTime = (seconds: number): string => {
    const hours = Math.floor(seconds / (60 * 60));
    const minutes = Math.floor(seconds / 60) % 60;

    return (hours > 0 ? String(hours).padStart(2, "0") + "h" : "") + String(minutes).padStart(2, "0") + "min";
};

export const sanitizeFilename = (filename: string): string => {
    return filename.replace(/[/ ]+/g, "_");
};

type Profiling = {
    name: string;
    startTimeInMs: number;
};

export const logProfileStart = (name: string): Profiling => {
    console.log(`[PROFILING ${name}] start...`);

    return {
        name,
        startTimeInMs: Date.now(),
    };
};

export const logProfileElapsed = (profiling: Profiling, step?: string): void => {
    const durationInSeconds = (Date.now() - profiling.startTimeInMs) / 1000;

    console.log(`[PROFILING ${profiling.name}] ${step ? `(${step}) ` : ""}ran for ${durationInSeconds}s`);
};

// Vue.nextTick() ensures that the virtual DOM has been synced with the DOM, *but* not that the DOM has been rendered by
// the browser. If there is heavy coputation going on, this might freeze the browser - so we also want to call and wait
// for 'requestAnimationFrame' to make sure we have been updated.
const tribleRequestAnimationFrame = (callback: FrameRequestCallback) => {
    // we need to call this twice because of a chrome bug. For some reason on my version I actually need to call it 3
    // times - this should have a negligible performance impact
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            requestAnimationFrame(callback);
        });
    });
};

export const forceNextTick = async (callback?: FrameRequestCallback) => {
    await Vue.nextTick();

    if (callback && typeof callback === "function") {
        tribleRequestAnimationFrame(callback);
    } else {
        return new Promise(resolve => {
            tribleRequestAnimationFrame(resolve);
        });
    }
};

export const notifyError = (text: string, title = "Something went wrong") => {
    Vue.notify({
        text,
        title,
        type: "error",
    });
};

/**
 * Pretty display of the number: 100000 => 100,000
 */
export const displayNumber = (number: number): string => {
    return String(number).replace(/(?<!^)(?=(?:\d{3})+$)/g, ",");
};
