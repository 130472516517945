
import Vue from "vue";
import { mapGetters } from "vuex";

import Section from "./Section.vue";

export default Vue.extend({
    components: { Section },
    computed: {
        ...mapGetters(["sections", "config"]),
    },
});
