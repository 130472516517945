
import Vue from "vue";
import { mapGetters } from "vuex";

import { displayNumber, getPercentage, roundPercentage } from "@/utils/helpers";
import { ActionTypes } from "@/store";
import TimeElapsed from "./TimeElapsed.vue";
import { filter } from "lodash/fp";
import { State } from "@/store/types";

type Mode = "deepL" | "proofread" | "translation";

export default Vue.extend({
    components: { TimeElapsed },
    computed: {
        ...mapGetters(["config", "sentences", "timerMode"]),
        isDocumentLoaded(): boolean {
            return this.wordsCount > 0;
        },
        isProofreading(): boolean {
            return this.timerMode === "proofread";
        },
        isTranslating(): boolean {
            return this.timerMode === "translation";
        },
        wordsCount(): number {
            return Object.keys(this.sentences).flatMap(id => this.sentences[Number(id)].original.split(/\s+/)).length;
        },
    },
    methods: {
        displayNumber,
        getPercentDone(mode: Mode): number {
            const doneSentencesCount = this.getWordsCount(mode);

            return getPercentage(doneSentencesCount, this.wordsCount);
        },
        getRoundPercentageDone(mode: Mode): string {
            const paddedPercentage = String(roundPercentage(this.getPercentDone(mode))).padStart(2, "0");

            return paddedPercentage.includes(".") ? paddedPercentage : paddedPercentage + ".0";
        },
        getWordsCount(mode: Mode): number {
            if (mode === "translation") {
                return filter("hasBeenTranslated", this.sentences).flatMap(s => s.original.split(/\s+/)).length;
            }
            if (mode === "proofread") {
                return filter(
                    id => Number(id) < (this.config.proofreadFlagId || 0),
                    Object.keys(this.sentences)
                ).flatMap(id => this.sentences[id].original.split(/\s+/)).length;
            }
            if (mode === "deepL") {
                return filter(s => !s.hasBeenTranslated && !!s.deepLTranslation, this.sentences).flatMap(s =>
                    s.original.split(/\s+/)
                ).length;
            }
            throw new Error("Unknown mode " + mode);
        },
        toggleTimerMode(mode: State["timer"]["mode"] | null) {
            const newMode = mode || (this.timerMode === "proofread" ? "translation" : "proofread");

            this.$store.commit(ActionTypes.setTimerMode, newMode);
        },
    },
});
