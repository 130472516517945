import axios from "axios";
import api from "@/utils/api";

export const MIMETYPE_HTMLZ = "application/vnd+htmlz+zip";

export const readFileAsText = (file: Blob | File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const text = event.target?.result;
            if (text) {
                resolve(text.toString());
            } else {
                reject("could not parse file as text");
            }
        };

        reader.readAsText(file);
    });
};

type UploadToS3Params = {
    fileName: string;
    fileType: string;
    paramProjectId: string;
};

export const uploadToS3 = async (fileName: string, fileType: string, file: File | Blob, paramProjectId = "") => {
    // get s3 signed url for upload
    const uploadToS3Params: UploadToS3Params = {
        fileName,
        fileType,
        paramProjectId,
    };

    const {
        data: { projectId, resourceUrl, s3Key, uploadUrl },
    } = await api.post("/upload-to-s3", uploadToS3Params);

    // upload file to s3
    await axios.put(uploadUrl, file, {
        headers: { "Content-Type": fileType },
    });

    return {
        projectId,
        resourceUrl,
        s3Key,
    };
};

export const downloadFile = async (fileUrl: string): Promise<Blob> => {
    const { data: file } = await axios.get(fileUrl, {
        responseType: "blob",
    });

    return file;
};

export const convertFile = async (fileUrl: string, format = "htmlz"): Promise<Blob> => {
    const { data: file } = await api.post("/convert-ebook", { fileUrl, format }, { responseType: "blob" });

    return file;
};

type ExtractIndexFromHtmlz = {
    s3Key: string;
};

export const extractHtmlFromHtmlz = async (s3Key: string): Promise<string> => {
    const extractIndexfromHtmlzParams: ExtractIndexFromHtmlz = {
        s3Key,
    };

    const {
        data: { htmlFile },
    } = await api.post("/extract-index-from-htmlz", extractIndexfromHtmlzParams);

    return htmlFile;
};
