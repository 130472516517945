import { cloneDeep, isEmpty } from "lodash/fp";
import { getClosestSentenceId, saveState } from "@/utils/store";
import { ActionTypes, MutationTypes } from "@/store";
import { translate, translateMap } from "@/utils/deepL";
import { getDatetimeString, notifyError } from "@/utils/helpers";
import { Comment, Context, DoneAction, DoneSentence, State } from "@/store/types";
import { defaultSentenceDisplay } from "@/utils/parseDocument";
import api from "@/utils/api";

type UpdateDeepLSentencesParams = {
    translatedTexts?: { [id: number]: string };
    restoreDeepLIds?: number[];
};

// number of sentences ahead to translate with DeepL when auto translate is set to true
const deepLAutotranslateLimit = parseInt(process.env.VUE_APP_DEEPL_AUTOTRANSLATE_LIMIT || "15");

enum actionTypes {
    comment = "comment",
    deepLAutoTranslateBatch = "deepLAutoTranslateBatch",
    editNextSentence = "editNextSentence",
    editPreviousSentence = "editPreviousSentence",
    mergePrevious = "mergePrevious",
    setEditedSentence = "setEditedSentence",
    translateSentenceDeepL = "translateSentenceDeepL",
    updateSentence = "updateSentence",
}

enum mutationTypes {
    comment = "comment",
    findAndReplace = "findAndReplace",
    hoverSentence = "hoverSentence",
    mergePrevious = "mergePrevious",
    redo = "redo",
    restoreSentence = "restoreSentence",
    setEditedSentence = "setEditedSentence",
    toggleSentencesAsBeingDeepLTranslated = "toggleSentencesAsBeingDeepLTranslated",
    undo = "undo",
    updateDeepLSentences = "updateDeepLSentences",
    updateSentence = "updateSentence",
}

const actions = {
    [actionTypes.comment](context: Context, { id, text }: { id: number; text: string }) {
        context.commit(MutationTypes.comment, {
            id,
            text,
            updatedAt: getDatetimeString(),
        });
    },
    async [actionTypes.deepLAutoTranslateBatch](context: Context, sentenceId: number) {
        const { state } = context;

        // checks a bit ahead to launch the translation before we actually reach the following sentences
        const needsTranslation =
            !state.sentences[sentenceId].deepLTranslation ||
            !state.sentences[sentenceId + 1]?.deepLTranslation ||
            !state.sentences[sentenceId + 2]?.deepLTranslation ||
            !state.sentences[sentenceId + 3]?.deepLTranslation;

        if (!needsTranslation) {
            return;
        }

        const textsToTranslate: { [id: number]: string } = {};
        // this holds the sentences that already have a deepLTranslation that we want to restore
        const restoreDeepLIds: number[] = [];

        for (let id = sentenceId; id < sentenceId + deepLAutotranslateLimit; id++) {
            const sentence = state.sentences[id];

            // fo not overwrite sentences that have been manually translated
            if (sentence && !sentence.hasBeenTranslated) {
                // check if we need to call DeepL API or restore the DeepL translation
                if (!sentence.deepLTranslation) {
                    textsToTranslate[id] = sentence.original;
                } else {
                    restoreDeepLIds.push(id);
                }
            }
        }

        const ids = Object.keys(textsToTranslate).map((id) => Number(id));

        context.commit(MutationTypes.toggleSentencesAsBeingDeepLTranslated, { ids, isBeingDeepLTranslated: true });

        try {
            const translatedTexts = await translateMap(
                textsToTranslate,
                state.config.sourceLang,
                state.config.targetLang
            );

            context.commit(MutationTypes.updateDeepLSentences, { translatedTexts, restoreDeepLIds });
        } catch (error) {
            notifyError(error);
            throw error;
        } finally {
            context.commit(MutationTypes.toggleSentencesAsBeingDeepLTranslated, { ids, isBeingDeepLTranslated: false });
        }
    },
    async [actionTypes.editNextSentence](context: Context) {
        const { state } = context;

        const currentEditedSentenceId = state.editedSentenceId ?? 0;

        const nextSentenceId = getClosestSentenceId(state, currentEditedSentenceId, true);

        await context.dispatch(ActionTypes.setEditedSentence, nextSentenceId);
    },
    async [actionTypes.editPreviousSentence](context: Context) {
        const { state } = context;

        const currentEditedSentenceId = state.editedSentenceId ?? state.maxId + 1;

        const previousSentenceId = getClosestSentenceId(state, currentEditedSentenceId, false);

        await context.dispatch(ActionTypes.setEditedSentence, previousSentenceId);
    },
    [actionTypes.mergePrevious](context: Context, sentenceId: number) {
        const { state } = context;

        // check state is instantiated - this shouldn't really happen
        if (isEmpty(state.sentences)) {
            return;
        }

        const sentence = state.sentences[sentenceId];
        // check the sentence isn't the first in a paragraph - we can't merge those
        if (sentence.paragraphPosition === 0) {
            return;
        }

        const previousSentenceId = getClosestSentenceId(state, sentenceId, false);
        if (!previousSentenceId) {
            return;
        }

        context.commit(MutationTypes.mergePrevious, {
            previousSentenceId,
            sentenceId,
        });

        context.commit(MutationTypes.setEditedSentence, previousSentenceId);

        saveState(context.state);
    },
    async [actionTypes.setEditedSentence](context: Context, sentenceId: number | null) {
        context.commit(MutationTypes.setEditedSentence, sentenceId);

        context.commit(MutationTypes.scrollToLocation, { id: sentenceId });

        // auto translate on open if deepL auto-translate is activated
        if (sentenceId && context.state.config.autoDeepLTranslate) {
            await context.dispatch(ActionTypes.deepLAutoTranslateBatch, sentenceId);
        }
    },
    async [actionTypes.translateSentenceDeepL](context: Context, sentenceId: number) {
        const { state } = context;

        const sentence = state.sentences[sentenceId];

        context.commit(MutationTypes.toggleSentencesAsBeingDeepLTranslated, {
            ids: [sentence.id],
            isBeingDeepLTranslated: true,
        });

        try {
            const updateDeepLSentencesData: UpdateDeepLSentencesParams = {};

            // if it has already been deepL translated, restore that translation instead of querying deepL
            if (sentence.deepLTranslation) {
                updateDeepLSentencesData.restoreDeepLIds = [sentence.id];
            } else {
                const translation = await translate(
                    sentence.original,
                    state.config.sourceLang,
                    state.config.targetLang
                );

                updateDeepLSentencesData.translatedTexts = { [sentence.id]: translation };
            }

            context.commit(MutationTypes.updateDeepLSentences, updateDeepLSentencesData);
        } catch (error) {
            notifyError(error);
            throw error;
        } finally {
            context.commit(MutationTypes.toggleSentencesAsBeingDeepLTranslated, {
                ids: [sentence.id],
                isBeingDeepLTranslated: false,
            });
        }
    },
    async [actionTypes.updateSentence](context: Context, sentenceData: { id: number; text: string }) {
        context.commit(MutationTypes.updateSentence, sentenceData);

        api.put(
            `/projects/${context.state.projectId}/sentences/${sentenceData.id}`,
            context.state.sentences[sentenceData.id]
        );
    },
};

/**
 * 'action' can be an array of multiple actions - if so, the actions will be done/undone in a single operation
 * this is useful for undoing sentence merging
 */
const addDoneActionToStack = (
    state: State,
    action: DoneAction | DoneAction[],
    isDoneStack = true,
    clearUndoneStack = true
) => {
    const actions = action instanceof Array ? action : [action];

    const stack = isDoneStack ? state.undoRedo.doneStack : state.undoRedo.undoneStack;

    stack.push(
        actions.map((a) => {
            const action = cloneDeep(a);

            // clean the display on each sentence before pushing them to the done stack
            if (action.actionType === "sentence") {
                action.display = { ...defaultSentenceDisplay };
            }

            return action;
        })
    );

    // if we edit a sentence manually, doing a redo doesn't mean anything anymore
    if (clearUndoneStack) {
        state.undoRedo.undoneStack = [];
    }
};

/**
 * Returns the DoneActions that has been replaced, so we can add them to the correct undo/redo stack
 */
const replaceDoneActionsInState = (state: State, actions: DoneAction[]): DoneAction[] => {
    const entities: DoneAction[] = [];

    actions.forEach((action) => {
        if (action.actionType === "sentence") {
            const previousSentence = cloneDeep(state.sentences[action.id]);
            entities.push({ ...previousSentence, actionType: "sentence" });

            // replace in the state
            const { actionType, ...entity } = action;
            state.sentences[action.id] = entity;
        } else if (action.actionType === "comment") {
            const previousComment = cloneDeep(state.comments[action.id]);
            entities.push({ ...previousComment, actionType: "comment" });

            // replace in the state
            const { actionType, ...entity } = action;
            state.comments[action.id] = entity;
        }
    });

    return entities;
};

export type FindReplaceForm = {
    find: string;
    isUsingRegex: boolean;
    replace: string;
};

const mutations = {
    [mutationTypes.comment](state: State, comment: Comment) {
        const previousComment = state.comments[comment.id];

        addDoneActionToStack(state, { ...previousComment, actionType: "comment" });

        state.comments[comment.id] = comment;

        saveState(state);
    },
    [mutationTypes.findAndReplace](state: State, { find, replace, isUsingRegex }: FindReplaceForm) {
        const changedSentences: DoneSentence[] = [];

        Object.keys(state.sentences).forEach((id) => {
            const sentence = state.sentences[Number(id)];
            const { translated } = sentence;

            const isMatch = isUsingRegex ? translated.match(new RegExp(find)) : translated.includes(find);

            if (isMatch) {
                changedSentences.push({ ...cloneDeep(sentence), actionType: "sentence" });

                const newTranslated = translated.replaceAll(isUsingRegex ? new RegExp(find, "g") : find, replace);

                state.sentences[Number(id)] = {
                    ...sentence,
                    translated: newTranslated,
                };
            }
        });

        addDoneActionToStack(state, changedSentences);
    },
    [mutationTypes.hoverSentence](state: State, { sentenceId, isHovered }: { sentenceId: number; isHovered: boolean }) {
        state.sentences[sentenceId].display.isBeingHovered = isHovered;
    },
    [mutationTypes.mergePrevious](
        state: State,
        { sentenceId, previousSentenceId }: { sentenceId: number; previousSentenceId: number }
    ) {
        const sentence = state.sentences[sentenceId];
        const previousSentence = state.sentences[previousSentenceId];

        addDoneActionToStack(state, [
            { ...sentence, actionType: "sentence" },
            { ...previousSentence, actionType: "sentence" },
        ]);

        // merge onto previous
        state.sentences[previousSentenceId] = {
            ...previousSentence,
            original: previousSentence.original + " " + sentence.original,
            hasBeenTranslated: false,
            deepLTranslation: [previousSentence.deepLTranslation ?? "", sentence.deepLTranslation ?? ""].join(" "),
            translated: previousSentence.translated + " " + sentence.translated,
        };

        // blank-out the sentence
        state.sentences[sentenceId] = {
            ...sentence,
            deepLTranslation: "",
            hasBeenTranslated: true, // count as translated in our statistics
            original: "",
            translated: "",
            translatedAt: getDatetimeString(),
        };
    },
    [mutationTypes.redo](state: State) {
        if (state.undoRedo.undoneStack.length === 0) {
            return;
        }

        const actions = state.undoRedo.undoneStack.pop() as DoneAction[];

        // replace the actions in the state
        const entities = replaceDoneActionsInState(state, actions);

        addDoneActionToStack(state, entities, true, false);

        saveState(state);
    },
    [mutationTypes.restoreSentence](state: State, sentenceId: number) {
        const sentence = state.sentences[sentenceId];

        addDoneActionToStack(state, { ...sentence, actionType: "sentence" });

        state.sentences[sentenceId] = {
            ...sentence,
            hasBeenTranslated: false,
            translated: sentence.original,
        };

        saveState(state);
    },
    [mutationTypes.setEditedSentence](state: State, sentenceId: number | null) {
        if (state.editedSentenceId) {
            state.sentences[state.editedSentenceId].display.isBeingEdited = false;
        }

        if (sentenceId) {
            state.sentences[sentenceId].display.isBeingEdited = true;
        }

        state.editedSentenceId = sentenceId;
    },
    [mutationTypes.toggleSentencesAsBeingDeepLTranslated](
        state: State,
        { ids, isBeingDeepLTranslated }: { ids: number[]; isBeingDeepLTranslated: boolean }
    ) {
        ids.forEach((id) => {
            state.sentences[id].display = {
                ...state.sentences[id].display,
                isBeingDeepLTranslated,
            };
        });
    },
    [mutationTypes.updateDeepLSentences](
        state: State,
        { translatedTexts, restoreDeepLIds }: UpdateDeepLSentencesParams
    ) {
        translatedTexts = translatedTexts || {};
        restoreDeepLIds = restoreDeepLIds || [];

        // save all affected sentences to the done stack
        const sentences = Object.keys(translatedTexts)
            .map((id) => Number(id))
            .concat(restoreDeepLIds)
            .map((id): DoneSentence => ({ ...state.sentences[id], actionType: "sentence" }));
        addDoneActionToStack(state, sentences);

        // update the newly translated text
        Object.keys(translatedTexts)
            .map((id) => Number(id))
            .forEach((id) => {
                const sentence = state.sentences[id];
                // @ts-ignore: I don't understand why TS thinks translatedTexts might be undefined
                const text = translatedTexts[id];

                state.sentences[id] = {
                    ...sentence,
                    deepLTranslation: text,
                    hasBeenTranslated: false,
                    translated: text,
                };
            });

        // restore deepL translation to these previously deepL translated sentences
        restoreDeepLIds.forEach((id) => {
            const sentence = state.sentences[id];
            state.sentences[id] = {
                ...sentence,
                hasBeenTranslated: false,
                // @ts-ignore: if we're here, we are on a sentence that has a deepL translation
                translated: sentence.deepLTranslation,
            };
        });

        saveState(state);
    },
    [mutationTypes.updateSentence](state: State, { id, text }: { id: number; text: string }) {
        const sentence = state.sentences[id];

        addDoneActionToStack(state, { ...sentence, actionType: "sentence" });

        state.sentences[id] = {
            ...sentence,
            hasBeenTranslated: true,
            translated: text,
            translatedAt: getDatetimeString(),
        };

        saveState(state);
    },
    [mutationTypes.undo](state: State) {
        if (state.undoRedo.doneStack.length === 0) {
            return;
        }

        const actions = state.undoRedo.doneStack.pop() as DoneAction[];

        // replace the actions in the state
        const entities = replaceDoneActionsInState(state, actions);

        // save the undone sentences on the undone stack
        addDoneActionToStack(state, entities, false, false);

        saveState(state);
    },
};

const getters = {
    canUndo: (state: State) => state.undoRedo.doneStack.length > 0,
    canRedo: (state: State) => state.undoRedo.undoneStack.length > 0,
    comments: (state: State) => state.comments,
    editedSentence: (state: State) => {
        if (state.editedSentenceId) {
            return state.sentences[state.editedSentenceId];
        }

        return null;
    },
    sentenceIds: (state: State) => {
        return Object.keys(state.sentences).map((key) => Number(key));
    },
    sentences: (state: State) => state.sentences,
};

export default {
    actions,
    actionTypes,
    getters,
    mutations,
    mutationTypes,
};
