type UrlGetter = (word: string) => string;
type UrlGetterTargetLang = (word: string, targetLang: string) => string;

export type LangResourceGetter = {
    definition: UrlGetter;
    synonym: UrlGetter;
    translation: UrlGetterTargetLang;
};

const definitionGetter = (lang: string): UrlGetter => {
    return (word: string) => `https://${lang.toLowerCase()}.wiktionary.org/wiki/${word}`;
};

const translationGetter = (lang: string): UrlGetterTargetLang => {
    return (word: string, targetLang: string) =>
        `https://translate.google.com/?sl=${lang.toLowerCase()}&tl=${targetLang.toLowerCase()}&text=${word}&op=translate`;
};

export const langResources: { [lang: string]: LangResourceGetter } = {
    EN: {
        definition: definitionGetter("EN"),
        synonym: (word: string) => `https://www.thesaurus.com/browse/${word}`,
        translation: translationGetter("EN"),
    },
    FR: {
        definition: definitionGetter("FR"),
        synonym: (word: string) => `https://www.cnrtl.fr/synonymie/${word}`,
        translation: translationGetter("FR"),
    },
};
