
import { some } from "lodash/fp";
import Vue from "vue";
import { mapGetters } from "vuex";

import Navigation from "@/components/Navigation/index.vue";
import SentenceEditor from "@/components/SentenceEditor.vue";
import { ActionTypes, MutationTypes } from "@/store";
import { Paragraph, } from "@/store/types";
import TranslationArea from "@/components/TranslationArea.vue";

export default Vue.extend({
    components: {
        TranslationArea,
        Navigation,
        SentenceEditor,
    },
    data() {
        return {
            activeParagraphs: [] as Paragraph[],
            editedSentenceId: null as null | number,
            highlightedCommentMinId: 0,
        };
    },
    watch: {
        editedSentence(value) {
            const id = value?.id;

            if (!id) {
                return;
            }

            // when we open the editor, we want to set the highlighted comment min id there
            this.highlightedCommentMinId = id;
        },
    },
    computed: {
        ...mapGetters([
            "comments",
            "config",
            "display",
            "paragraphs",
            "sections",
            "sentenceIds",
            "sentences",
            "editedSentence",
        ]),
        hasComments(): boolean {
            return some(c => !!c.text, this.comments);
        },
    },
    methods: {
        handleAddComment() {
            if (!this.editedSentence) {
                return;
            }

            const text = prompt("Comment:", this.comments[this.editedSentence.id].text || "");

            if (text) {
                this.$store.commit(MutationTypes.comment, {
                    id: this.editedSentence.id,
                    text,
                });
            }
        },
        handleMergePrevious() {
            if (!this.editedSentence) {
                return;
            }

            this.$store.dispatch(ActionTypes.mergePrevious, this.editedSentence.id);
        },
        handleNextSentence() {
            this.$store.dispatch(ActionTypes.editNextSentence);
        },
        handlePreviousSentence() {
            this.$store.dispatch(ActionTypes.editPreviousSentence);
        },
        handleSentenceUpdated() {
            // when the sentence is updated its length might change, which can throw off the precomputed sentence
            // locations and make the scrolling out of sync. Recompute to avoid this.
            // @ts-ignore
            this.$refs.target.updateSentenceLocations();
        },
        jumpToNextComment() {
            for (let i = 0; i < this.sentenceIds.length; i++) {
                // cycle the id, starting with the next value and increment, until end of ids then start again from 1
                const id = ((this.highlightedCommentMinId + i) % this.sentenceIds.length) + 1;

                if (this.comments[id].text) {
                    this.highlightedCommentMinId = id;
                    return this.$store.commit(MutationTypes.scrollToLocation, {id});
                }
            }
        },
        jumpToNextUntranslated() {
            const sentencesCount = Object.keys(this.sentences).length;

            let hasFoundTranslatedSentence = false;

            for (let i = 0; i < sentencesCount - 1; i++) {
                const id = ((this.display.scrollTo.id + i) % sentencesCount) + 1;

                // if there are multiple untranslated lines after our current untranslated line, we don't want to go
                // through them one by one. Fast forward until we find translated lines, and only then stop on
                // untranslated line
                if (this.sentences[id].hasBeenTranslated) {
                    hasFoundTranslatedSentence = true;
                }

                // the 'id === 1' is to prevent looping on the doc - if the first line is untranslated, we stop here
                if ((hasFoundTranslatedSentence || id === 1) && !this.sentences[id].hasBeenTranslated) {
                    return this.$store.commit(MutationTypes.scrollToLocation, {id});
                }
            }
        },
        jumpToProofreadFlag() {
            return this.$store.commit(MutationTypes.scrollToLocation, {id: this.config.proofreadFlagId});
        },
        toggleEditedSentence(sentenceId: number | null) {
            this.$store.dispatch(ActionTypes.setEditedSentence, sentenceId);
        },
    },
});
