import { isEmpty, zipObject } from "lodash/fp";
import api from "@/utils/api";

const autoTranslate = async (texts: string[], sourceLang: string, targetLang: string): Promise<string[]> => {
    const params = {
        sourceLang,
        targetLang,
        texts,
    };

    const {
        data: { translatedTexts },
    } = await api.post("/auto-translate", params);

    if (!(translatedTexts instanceof Array)) {
        throw new Error("The translation failed by returning " + JSON.stringify(translatedTexts));
    }

    return translatedTexts;
};

export const translate = async (text: string, sourceLang: string, targetLang: string): Promise<string> => {
    const translatedTexts = await autoTranslate([text], sourceLang, targetLang);

    return translatedTexts[0];
};

export const translateMap = async (
    texts: { [id: number]: string },
    sourceLang: string,
    targetLang: string
): Promise<{ [id: number]: string }> => {
    if (isEmpty(texts)) {
        return {};
    }

    const ids = Object.keys(texts);
    const textArray = ids.map((id) => texts[Number(id)]);

    const translatedTexts = await autoTranslate(textArray, sourceLang, targetLang);

    return zipObject(ids, translatedTexts);
};
